<template>
  <div class="body-widget-subtitle-1-superwrapper" :class="[textCentered ? 'centered' : '']">
    <div v-for="component in subtitleComponents" :key="component" class="body-widget-subtitle-1-wrapper">
      <img class="body-widget-subtitle-1__icon" :src="icon" />
      <div class="body-widget-subtitle-1__text" :class="subtitleColorClass">
        {{ component }}
      </div>
    </div>
  </div>
</template>

<script>
import timeWhite from "@/assets/dynamic-frontpage/cards-icons/time-white.png";
import timeBlack from "@/assets/dynamic-frontpage/cards-icons/time-grey.png";
import { getString } from "@/services/utils.js";
import dynamicFrontpageMixin from "@/mixins/dynamicFrontpageMixin.js";
import moment from "moment-timezone";
export default {
  mixins: [dynamicFrontpageMixin],
  props: {
    card: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    },
    textCentered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      icon: timeWhite
    };
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    dateNow() {
      return this.$store.state.dateNow;
    },
    momentServer() {
      return moment(this.dateNow.time);
    },
    subtitleComponents() {
      if (this.subtitle) {
        return this.subtitle.split(" · ");
      }
      return null;
    },
    subtitle() {
      return this.getOpenCloseStringServerNow(this.card, this.momentServer);
    },
    subtitleColorClass() {
      if (!this.subtitleColor) return "caption-white medium";
      return "caption-grey";
    }
  },
  created() {
    if (this.subtitleColor) {
      this.icon = timeBlack;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-subtitle-1-superwrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 2px;
  &.centered {
    position: absolute;
    left: 16px;
    bottom: 16px;
  }
}
.body-widget-subtitle-1-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.body-widget-subtitle-1__icon {
  margin-right: 4px;
  height: 16px;
  width: 16px;
}
.body-widget-subtitle-1__text {
  width: 100%;
  text-align: left;
}
</style>
