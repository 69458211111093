<template>
  <div class="footer-widget-floating-bubble-wrapper">
    <div class="floating-bubble-bottom-right" :style="{ 'background-color': backgroundColor }" @click="action">
      <img :src="getIcon(widget)" class="floating-bubble-icon" />
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  emits: ["bounds", "action"],
  data() {
    return {
      children: []
    };
  },
  computed: {
    backgroundColor() {
      if (!this.widget?.backgroundColor) {
        return this.$store.state.mainColor;
      }
      return this.widget?.backgroundColor;
    }
  },
  created() {
    this.loadItems();
    this.$emit("bounds", { height: 102, isTransparent: true });
  },
  methods: {
    getImageUrl,
    getIcon(widget) {
      return widget?.icon ? getImageUrl(widget.icon, true) : null;
    },
    getTitle(widget) {
      return widget.translatableName ? translate(widget.translatableName) : null;
    },
    async loadItems() {
      this.loading = true;
      if (this.widget.children?.length > 0) {
        this.children = this.widget.children;
      }
      this.loading = false;
    },
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.footer-widget-floating-bubble-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px 32px 16px;
  width: calc(100% - 32px);
}
.floating-bubble-bottom-right {
  right: 32px;
  bottom: 32px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  box-shadow: 0 12px 22px 0 rgba(105, 113, 129, 0.2);
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.floating-bubble-icon {
  width: 36px;
  flex: 36px 0 0;
  height: 36px;
}
.footer-widget-shortcut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  &.child-0 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    flex: 100px 1 0;
    padding-right: 20px;
  }
  &.child-1 {
    border-radius: 40px;
    flex-basis: 80px;
    height: 80px;
    position: absolute;
    width: 80px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }
  &.child-2 {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    flex: 100px 1 0;
    padding-left: 20px;
  }
}
.footer-widget-floating-shortcut__center_overlay {
  height: 80px;
  width: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  border-radius: 40px;
}
.footer-widget-floating-shortcut__image {
  width: 24px;
  height: 24px;
}
.footer-widget-floating-shortcut__title {
  .caption-white;
  margin-top: 4px;
}
</style>
