<template>
  <div class="body-widget-text-separator-wrapper">
    <div class="body-widget-text-separator">
      <div class="body-widget-text-separator__text" :style="{ 'font-size': fontSize, color: textColor }">
        {{ text }}
      </div>
      <component :is="getUnderlineComponent()" class="body-widget-text-separator__image" :fill-color="underlineColor" />
    </div>
  </div>
</template>

<script>
import { translate } from "@/services/utils.js";
import { defineAsyncComponent } from "vue";
const Underline1 = defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Underlines/Underline1.vue"));
const Underline2 = defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Underlines/Underline2.vue"));
const Underline3 = defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Underlines/Underline3.vue"));
const Underline4 = defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Underlines/Underline4.vue"));
export default {
  components: {
    Underline1,
    Underline2,
    Underline3,
    Underline4
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {
    text() {
      if (!this.widget?.widgetTitleOptions?.title) {
        return "";
      }
      return translate(this.widget.widgetTitleOptions.title);
    },
    textColor() {
      return "#34323D";
    },
    fontSize() {
      return this.widget.widgetTitleOptions.fontSize + "px";
    },
    underlineColor() {
      if (!this.widget?.widgetTitleOptions?.underlineColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.widgetTitleOptions.underlineColor;
    }
  },
  methods: {
    getUnderlineComponent() {
      if (!this.widget?.widgetTitleOptions?.underlineStyle) {
        return null;
      }
      return "Underline" + this.widget.widgetTitleOptions.underlineStyle;
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-text-separator-wrapper {
  width: 100%;
  min-height: 90px;
}
.body-widget-text-separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
}
.body-widget-text-separator__text {
  font-weight: 400;
  text-align: center;
  padding: 0 16px;
}
.body-widget-text-separator__image {
  width: auto;
  height: auto;
  margin-top: 8px;
}
.body-widget-text-separator__image path {
  // fill: #f00;
}
</style>
