<template>
  <div class="body-widget-subtitle-1-wrapper" :class="[textCentered ? 'centered' : '']">
    <div v-if="subtitle" class="body-widget-subtitle-1__text" :class="subtitleColorClass">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import { setCurrencyToPrice, getString } from "@/services/utils.js";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    },
    textCentered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    subtitle() {
      if (!this.card.priceFrom) return null;
      return (
        getString(this.strings, "FROM_PRICE", this.user) +
        " " +
        setCurrencyToPrice(this.card.priceFrom, this.card.currencyData?.symbol)
      );
    },
    subtitleColorClass() {
      if (!this.subtitleColor) return "caption-white medium";
      return "caption-black";
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-subtitle-1-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &.centered {
    position: absolute;
    left: 16px;
    bottom: 16px;
  }
}
.body-widget-subtitle-1__text {
  padding-top: 4px;
  width: 100%;
  text-align: left;
}
</style>
