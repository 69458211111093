<template>
  <div class="widget-underline4-wrapper">
    <svg
      width="375px"
      height="40px"
      viewBox="0 0 375 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>3A242392-1839-4FE0-9250-2BCB776AF1F6</title>
      <g id="Dynamic-Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CreatedContent" transform="translate(-158, -594)">
          <rect fill="rgba(255,255,255,0)" x="0" y="0" width="1600" height="2171"></rect>
          <g id="Components-/-Labels-/-Dividers-/-Dynamic-/-01" transform="translate(158, 509)">
            <rect id="Rectangle" fill="rgba(255,255,255,0)" x="0" y="0" width="375" height="125"></rect>
            <polygon id="base" fill="rgba(255,255,255,0)" fill-rule="nonzero" points="0 0 375 0 375 86 0 86"></polygon>
            <g id="UI-/Shapes/-Categories-/-05" transform="translate(0, 85)" :fill="fillColor" fill-rule="nonzero">
              <g id="Stroke-1" transform="translate(150, 16)">
                <path
                  id="Path"
                  d="M73.2888889,2.66666667 C71.6888889,2.66666667 70.9333333,3.46666667 69.7333333,4.87111111 C68.5333333,6.27555556 67.0311111,8 64.1333333,8 C61.2355556,8 59.7422222,6.26666667 58.5422222,4.87111111 C57.3422222,3.47555556 56.5688889,2.66666667 54.9688889,2.66666667 C53.3688889,2.66666667 52.6133333,3.46666667 51.4133333,4.87111111 C50.2133333,6.27555556 48.7111111,8 45.8133333,8 C42.9155556,8 41.4133333,6.26666667 40.2133333,4.87111111 C39.0133333,3.47555556 38.2222222,2.66666667 36.6488889,2.66666667 C35.0755556,2.66666667 34.2844444,3.46666667 33.0933333,4.87111111 C31.9022222,6.27555556 30.3911111,8 27.4844444,8 C24.5777778,8 23.1111111,6.26666667 21.8933333,4.87111111 C20.6755556,3.47555556 19.92,2.66666667 18.32,2.66666667 C16.72,2.66666667 15.9644444,3.46666667 14.7644444,4.87111111 C13.5644444,6.27555556 12.0622222,8 9.16444444,8 C6.26666667,8 4.76444444,6.26666667 3.55555556,4.87111111 C2.34666667,3.47555556 1.6,2.66666667 0,2.66666667 L0,0 C2.90666667,0 4.39111111,1.77777778 5.59111111,3.12888889 C6.79111111,4.48 7.56444444,5.33333333 9.16444444,5.33333333 C10.7644444,5.33333333 11.52,4.53333333 12.72,3.12888889 C13.92,1.72444444 15.4222222,0 18.32,0 C21.2177778,0 22.72,1.77777778 23.92,3.12888889 C25.12,4.48 25.8844444,5.33333333 27.4844444,5.33333333 C29.0844444,5.33333333 29.8488889,4.54222222 31.04,3.12888889 C32.2311111,1.71555556 33.7777778,0 36.6488889,0 C39.52,0 41.04,1.77777778 42.24,3.12888889 C43.44,4.48 44.2044444,5.33333333 45.8133333,5.33333333 C47.4222222,5.33333333 48.1688889,4.54222222 49.3688889,3.12888889 C50.5688889,1.71555556 52.0711111,0 54.9688889,0 C57.8666667,0 59.4133333,1.77777778 60.56,3.12888889 C61.7066667,4.48 62.5333333,5.33333333 64.1333333,5.33333333 C65.7333333,5.33333333 66.4888889,4.54222222 67.6888889,3.12888889 C68.8888889,1.71555556 70.3911111,0 73.2888889,0 C76.1866667,0 74.8888889,2.66666667 73.2888889,2.66666667 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: true,
      default: "#7866FE"
    }
  },
  computed: {
    fill() {
      return this.fillColor;
    }
  }
};
</script>

<style></style>
