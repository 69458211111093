<template>
  <div class="body-widget-cta-1-wrapper" @click.stop="$emit('ctaAction')">
    <div v-if="text" class="body-widget-cta-1__text" :style="style">
      {{ text }}
    </div>
  </div>
</template>

<script>
import { translate } from "@/services/utils.js";
import { ctaStyles } from "@/utils/enums/widgetsFrontpage.js";
export default {
  props: {
    cardOptions: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    }
  },
  emits: ["ctaAction"],
  computed: {
    text() {
      if (!this.cardOptions.ctaText) return null;
      return translate(this.cardOptions.ctaText);
    },
    ctaColor() {
      return this.cardOptions.ctaColor;
    },
    style() {
      switch (this.cardOptions.ctaType) {
        case ctaStyles.CTA_STYLE_2:
          return { "background-color": this.ctaColor, color: "white", border: "1px solid " + this.ctaColor };
        default:
          return { color: this.ctaColor, border: "1px solid " + this.ctaColor };
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-cta-1-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.body-widget-cta-1__text {
  width: 100%;
  text-align: left;
  margin-top: 4px;
  padding: 2px 4px;
  border-radius: 16px;
  text-align: center;
  .h4-black;
}
</style>
