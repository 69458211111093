<template>
  <div class="footer-widget-floating-shortcuts-wrapper">
    <div v-if="children.length > 0" class="footer-widget-floating-shortcuts animated fadeIn">
      <div
        v-for="(child, index) in children"
        :key="'footer-shortcut-' + index"
        class="footer-widget-shortcut"
        :class="['child-' + index]"
        :style="{ 'background-color': backgroundColor }"
        @click="action(child, index)"
      >
        <div v-if="index === 1" class="footer-widget-floating-shortcut__center_overlay"></div>
        <img class="footer-widget-floating-shortcut__image" :src="getIcon(child, true)" />
        <div v-if="getTitle(child)" class="footer-widget-floating-shortcut__title">{{ getTitle(child) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  emits: ["bounds", "action"],
  data() {
    return {
      children: []
    };
  },
  computed: {
    backgroundColor() {
      if (!this.widget.mainColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.mainColor;
    }
  },
  created() {
    this.loadItems();
    this.$emit("bounds", { height: 100, isTransparent: true });
  },
  methods: {
    getImageUrl,
    getIcon(child) {
      return child?.photographs ? getImageUrl(child.photographs[0], true) : null;
    },
    getTitle(child) {
      return child.translatableName ? translate(child.translatableName) : null;
    },
    async loadItems() {
      this.loading = true;
      if (this.widget.children?.length > 0) {
        this.children = this.widget.children;
      }
      this.loading = false;
    },
    action(payload, index) {
      this.$emit("action", { ...payload, ...{ widget: this.widget, index: index } });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.footer-widget-floating-shortcuts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px 16px;
}
.footer-widget-shortcut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  &.child-0 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    flex: 100px 1 0;
    padding-right: 20px;
  }
  &.child-1 {
    border-radius: 40px;
    flex-basis: 80px;
    height: 80px;
    position: absolute;
    width: 80px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }
  &.child-2 {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    flex: 100px 1 0;
    padding-left: 20px;
  }
}
.footer-widget-floating-shortcut__center_overlay {
  height: 80px;
  width: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  border-radius: 40px;
}
.footer-widget-floating-shortcut__image {
  width: 24px;
  height: 24px;
}
.footer-widget-floating-shortcut__title {
  .caption-white;
  text-align: center;
  margin-top: 4px;
}
</style>
