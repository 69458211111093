<template>
  <div v-if="children.length > 0" class="body-widget-upsell-wrapper">
    <div class="body-widget-upsell animated fadeIn">
      <upsell-frontpage
        :upsellnodes="children"
        :upselldark-background="true"
        :upsell-background-color="widget.backgroundColor"
        :show-square-borders="showSquareBorders"
        @action="action"
      ></upsell-frontpage>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const UpsellFrontpage = defineAsyncComponent(() => import("@/components/UpSell/UpSellFrontpage.vue"));
export default {
  components: {
    UpsellFrontpage
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      default: false
    }
  },
  emits: ["bounds", "action"],
  data() {
    return {
      children: []
    };
  },
  created() {
    this.loadItems();
    this.$emit("bounds", { height: 215, isTransparent: false });
  },
  methods: {
    async loadItems() {
      this.loading = true;
      if (this.widget.children?.length > 0) {
        this.widget.children.forEach(child => {
          if (child.active) {
            this.children.push({
              translatableName: child.translatableName,
              translatableDescription: child.translatableDescription,
              icon: child.photographs[0],
              itemType: child.itemType,
              itemId: child.itemId,
              website: child.website,
              cmsModule: child.cmsModule,
              openWebsiteInBrowser: child.openWebsiteInBrowser
            });
          }
        });
      }
      this.loading = false;
    },
    action(payload, index) {
      this.$emit("action", { ...this.children[index], ...{ widget: this.widget, index: index } });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-upsell-wrapper {
  height: 215px;
  position: relative;
}
.body-widget-upsell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}
.footer-widget-shortcut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
  &.child-0-1 {
    border-radius: 40px;
    width: 100%;
    flex-direction: row;
  }
  &.child-0-2 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    flex: 100px 1 0;
    flex-direction: row;
    padding-left: 16px;
  }
  &.child-0-3 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    flex: 100px 1 0;
    height: 60px;
  }
  &.child-1-2 {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    flex: 100px 1 0;
    flex-direction: row;
    padding-right: 16px;
  }
  &.child-1-3 {
    border-radius: 0;
    flex-basis: 100px;
    height: 60px;
  }
  &.child-2-3 {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    flex: 100px 1 0;
    height: 60px;
  }
}
.body-widget-shortcut__center_overlay {
  height: 80px;
  width: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  border-radius: 40px;
}
.body-widget-shortcut__image {
  width: 24px;
  height: 24px;
}
.body-widget-shortcut__title {
  .caption-white;
  .medium;
  margin-top: 4px;
  &.length-1,
  &.length-2 {
    .subheader-white;
    .medium;
    margin-left: 16px;
  }
}
.vl {
  position: absolute;
  right: 0;
  top: 8px;
  width: 1px;
  background-color: white;
  &.length-2 {
    height: 34px;
  }
  &.length-3 {
    height: 44px;
  }
}
</style>
