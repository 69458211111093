import { previewLegalClause } from "../services/utils.js";
import { EventBus } from "../services/eventBus";
const Mixin = {
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    establishment() {
      return this.$store.state.establishment;
    }
  },
  methods: {
    async goToLegalLinkUrl(clauseId) {
      try {
        const winOpen = window.open();
        const response = await this.$store.dispatch("getLegalText", { id: clauseId });
        if (response) {
          previewLegalClause(response, winOpen);
        }
      } catch (error) {
        return error;
      }
    },
    showCookieSettings() {
      EventBus.$emit("showCookieSettings");
    },
    getPrivacyOpenOutside() {
      const string = this.strings.find(string => string.strKey === "PRIVACY_POLICY_CHECKBOX");
      if (string && string.custom && string.custom[this.establishment.id] && string.custom[this.establishment.id].en) {
        return string.custom[this.establishment.id].en === "true";
      } else {
        return false;
      }
    },
    getTermsOpenOutside() {
      const string = this.strings.find(string => string.strKey === "TERMS_AND_CONDITIONS_CHECKBOX");
      if (string && string.custom && string.custom[this.establishment.id] && string.custom[this.establishment.id].en) {
        return string.custom[this.establishment.id].en === "true";
      } else {
        return false;
      }
    }
  }
};

export default Mixin;
