<template>
  <div class="footer-widget-wrapper">
    <component :is="getWidgetComponent(widget)" :widget="widget" @bounds="emitBounds" @action="action"></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { footerWidgets } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    FooterWidgetFloatingShortcuts: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/FooterWidgetFloatingShortcuts.vue")
    ),
    FooterWidgetFloatingBubble: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/FooterWidgetFloatingBubble.vue")
    )
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  emits: ["action", "bounds"],
  methods: {
    getWidgetComponent(widget) {
      return footerWidgets[widget.templateId];
    },
    emitBounds(bounds) {
      this.$emit("bounds", bounds);
    },
    action(payload) {
      this.$emit("action", payload);
    }
  }
};
</script>

<style>
.footer-widget-wrapper {
  background-color: rgba(0, 0, 0, 0);
}
</style>
