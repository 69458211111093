<template>
  <div class="widget-underline4-wrapper">
    <svg
      width="375px"
      height="40px"
      viewBox="0 0 375 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>underline1</title>
      <g id="Dynamic-Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CreatedContent" transform="translate(-580, -594)">
          <rect fill="rgba(255,255,255,0)" x="0" y="0" width="1600" height="2171"></rect>
          <g id="Components-/-Labels-/-Dividers-/-Dynamic-/-01" transform="translate(580, 509)">
            <rect id="Rectangle" fill="rgba(255,255,255,0)" x="0" y="0" width="375" height="125"></rect>
            <g id="UI-/Shapes/-Categories-/-05" transform="translate(0, 85)" :fill="fillColor" fill-rule="nonzero">
              <rect id="shape" x="172" y="18" width="32" height="4"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: true,
      default: "#7866FE"
    }
  },
  computed: {
    fill() {
      return this.fillColor;
    }
  }
};
</script>

<style></style>
