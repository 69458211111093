<template>
  <div class="body-widget-shortcuts-wrapper">
    <div v-if="children.length > 0" class="body-widget-shortcuts">
      <div
        v-for="(child, index) in children"
        :key="'footer-shortcut-' + index"
        class="footer-widget-shortcut"
        :class="['child-' + index + '-' + children.length, showSquareBorders ? 'show-square-borders' : '']"
        :style="{ 'background-color': backgroundColor }"
        @click="action(child, index)"
      >
        <img class="body-widget-shortcut__image" :src="getIcon(child, true)" />
        <div v-if="getTitle(child)" class="body-widget-shortcut__title" :class="['length-' + children.length]">
          {{ getTitle(child) }}
        </div>
        <div v-if="index < 2" class="vl" :class="['length-' + children.length]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["bounds", "action"],
  data() {
    return {
      children: []
    };
  },
  computed: {
    backgroundColor() {
      return this.widget.mainColor || this.$store.state.mainColor;
    }
  },
  watch: {
    widget: {
      handler() {
        this.loadItems();
      },
      deep: true
    }
  },
  created() {
    this.loadItems();
    this.$emit("bounds", { height: 100, isTransparent: true });
  },
  methods: {
    getImageUrl,
    getIcon(child) {
      return child?.photographs ? getImageUrl(child.photographs[0], true) : null;
    },
    getTitle(child) {
      return child.translatableName ? translate(child.translatableName) : null;
    },
    async loadItems() {
      this.loading = true;
      if (this.widget.children?.length > 0) {
        this.children = this.widget.children;
      }
      this.loading = false;
    },
    action(payload, index) {
      this.$emit("action", { ...payload, ...{ widget: this.widget, index: index } });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-shortcuts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 8px 16px;
}
.footer-widget-shortcut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
  &.child-0-1 {
    border-radius: 40px;
    width: 100%;
    flex-direction: row;
  }
  &.child-0-2 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    flex: 100px 1 0;
    flex-direction: row;
    padding-left: 16px;
  }
  &.child-0-3 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    flex: 100px 1 0;
    height: 60px;
  }
  &.child-1-2 {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    flex: 100px 1 0;
    flex-direction: row;
    padding-right: 16px;
  }
  &.child-1-3 {
    border-radius: 0;
    flex-basis: 100px;
    height: 60px;
  }
  &.child-2-3 {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    flex: 100px 1 0;
    height: 60px;
  }
}
.body-widget-shortcut__center_overlay {
  height: 80px;
  width: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  border-radius: 40px;
}
.body-widget-shortcut__image {
  width: 24px;
  height: 24px;
}
.body-widget-shortcut__title {
  .caption-white;
  .medium;
  margin-top: 4px;
  &.length-1,
  &.length-2 {
    .subheader-white;
    .medium;
    margin-left: 16px;
  }
}
.vl {
  position: absolute;
  right: 0;
  top: 8px;
  width: 1px;
  background-color: white;
  opacity: 0.3;
  &.length-2 {
    height: 34px;
  }
  &.length-3 {
    height: 44px;
  }
}
</style>
