<template>
  <div ref="main" class="widgets-front-main-component" :class="[scrollDisabled ? 'disable' : '']">
    <div v-if="isDraft" class="draft-banner">
      <div class="draft-label">{{ getString("CMS_DRAFT") }}</div>
    </div>
    <mini-header
      v-if="showFixedHeader"
      class="mini-header"
      :class="[fixedHeaderVisible ? 'show' : '', avoidAnimateHeader ? 'avoid-animate' : '']"
      :widget="headerWidget"
      @show-user-profile="showUserProfile"
    />
    <!-- <div class="widgets-front-background" /> -->
    <header-widget
      v-if="headerWidget"
      class="frontpage-header-widget"
      :style="{ height: headerWidgetHeight + 'px', width: '100%' }"
      :widget="headerWidget"
      :frontpage-background-color="backgroundColor"
      :frontpage-text-color="textColor"
      :fixed-header-visible="fixedHeaderVisible"
      :frontpage-data="frontpageData"
      @height="setHeaderWidgetHeight"
      @show-inbox="showInbox"
      @show-user-profile="showUserProfile"
      @shortcut-tapped="shortcutTapped"
      @show-front-side-menu="showFrontSideMenu"
    ></header-widget>
    <body-widgets
      v-if="bodyWidgets"
      class="frontpage-body-widgets"
      :widgets="bodyWidgets"
      :frontpage-text-color="textColor"
      :style="bodyWidgetStyle"
      :extra-div-bottom-height="extraDivBottomHeight"
      :show-square-borders="showSquareBorders"
      :scroll="scroll"
      @action="action"
      @action-all="actionAll"
      @cta-action="ctaAction"
    ></body-widgets>
    <footer-widget
      v-if="footerWidget"
      class="frontpage-footer-widget"
      :widget="footerWidget"
      :style="footerWidgetStyle"
      :survey-v3-proactive-visible="surveyV3ProactiveVisible"
      @bounds="setFooterWidgetBounds"
      @action="action"
    ></footer-widget>
    <widgets-home-popups
      :profile-popup-visible="showProfilePopup"
      :welcome-message-id="welcomeMessageId"
      :has-footer-shortcuts="hasFooterShortcuts"
      @hide-profile-popup="showProfilePopup = false"
      @show-user-route="showUserRoute"
      @survey-v3-proactive-visible="surveyV3ProactiveVisible = true"
      @show-login="showLogin"
    />
    <div v-if="scrollDisabled" class="animated fadeIn profile-side-menu-fade" />
    <profile-side-menu
      v-if="frontpageData && profileInteractive"
      class="profile-side-menu"
      :frontpage-data="frontpageData"
      :profile-visible="profileVisible"
      :class="[profileVisible ? 'visible' : '']"
      @hide="hideProfile"
      @show-login="showLogin"
      @show-inbox="showInbox"
      @show-legal-texts="showLegalTexts"
      @select-language="selectLanguage"
      @show-activity="showActivity"
      @show-all-activity="showAllActivity"
    />
    <front-side-menu
      v-if="frontSideMenuAvailable"
      class="front-side-menu"
      :class="{ visible: frontSideMenuVisible }"
      :frontpage-data="frontpageData"
      :side-menu-visible="frontSideMenuVisible"
      @hide="hideFrontSideMenu"
      @action="actionAndHideFrontSideMenu"
    />
  </div>
</template>

<script>
const BODY_PADDING_TOP = 16;
const ONE_HOUR = 3600;
const SHOW_FIXED_HEADER_THRESHOLD = 180;
import { defineAsyncComponent } from "vue";
import { getImageUrl, getString } from "@/services/utils.js";
import widgetsFrontpageMixin from "@/mixins/widgetsFrontpageMixin.js";
import * as types from "@/store/mutation-types.js";
import { footerOptions } from "@/utils/enums/widgetsFrontpage.js";
import { EventBus } from "@/services/eventBus";

export default {
  components: {
    CircleSpinner: defineAsyncComponent(() => import("@/components/Loading/CircleSpinner.vue")),
    HeaderWidget: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/MainComponents/HeaderWidget.vue")),
    FooterWidget: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/MainComponents/FooterWidget.vue")),
    BodyWidgets: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/MainComponents/BodyWidgets.vue")),
    WidgetsHomePopups: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/MainComponents/WidgetsHomePopups.vue")
    ),
    MiniHeader: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/MainComponents/MiniHeader.vue")),
    ProfileSideMenu: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/MainComponents/ProfileSideMenu.vue")
    ),
    FrontSideMenu: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/MainComponents/FrontSideMenu.vue"))
  },
  mixins: [widgetsFrontpageMixin],
  data() {
    return {
      headerWidgetHeight: 0,
      footerWidgetHeight: 0,
      footerWidgetIsTransparent: false,
      bodyWidgetsSpaceToSubstract: 0,
      loading: false,
      showProfilePopup: false,
      userRouteAfterProfilePopup: null,
      fixedHeaderVisible: false,
      fixedHeaderInteractive: false,
      surveyV3ProactiveVisible: false,
      profileVisible: false,
      frontSideMenuVisible: false,
      scroll: 0,
      profileInteractive: false,
      frontSideMenuInteractive: false,
      avoidAnimateHeader: false
    };
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    frontpageData() {
      return this.$store.state.frontpage.data;
    },
    headerWidget() {
      return this.frontpageData ? this.frontpageData.headerWidget : null;
    },
    footerWidget() {
      if (this.$router.currentRoute.value.query.demoWidget) {
        return null;
      }
      return this.frontpageData ? this.frontpageData.footerWidget : null;
    },
    bodyWidgets() {
      if (this.$router.currentRoute.value.query.demoWidget && this.frontpageData?.bodyWidgets) {
        return [this.frontpageData?.bodyWidgets[this.$router.currentRoute.value.query.demoWidget]];
      }
      const bodyWidgets = [];
      if (this.showUpsell) {
        bodyWidgets.push(this.frontpageData.headerWidget.upsell);
      }
      if (this.showMyEvent) {
        bodyWidgets.push(...this.myEventWidgets);
      }
      if (this.frontpageData?.bodyWidgets) {
        bodyWidgets.push(...this.frontpageData.bodyWidgets);
      }
      return bodyWidgets.length > 0 ? bodyWidgets : null;
    },
    backgroundColor() {
      return this.frontpageData ? this.frontpageData.backgroundColor : null;
    },
    textColor() {
      return this.frontpageData ? this.frontpageData.textColor : null;
    },
    establishmentHash() {
      return this.$store.state.establishmentHash;
    },
    showUpsell() {
      return this.frontpageData?.headerWidget?.upsell?.showUpsell;
    },
    dateNow() {
      return this.$store.state.dateNow;
    },
    welcomeMessageId() {
      return this.frontpageData?.welcomeMessage && this.frontpageData?.welcomeMessageId > 0
        ? this.frontpageData.welcomeMessageId
        : null;
    },
    hasFooterShortcuts() {
      return this.frontpageData?.footerWidget?.templateId === footerOptions.FOOTER_OPTION_SHORTCUTS;
    },
    hasFooterBubble() {
      return this.frontpageData?.footerWidget?.templateId === footerOptions.FOOTER_OPTION_BUBBLE;
    },
    extraDivBottomHeight() {
      let extraHeight = 0;
      if (this.footerWidgetIsTransparent) {
        extraHeight = this.footerWidgetHeight;
      }
      if (this.surveyV3ProactiveVisible && this.hasFooterBubble) {
        extraHeight += 80;
      } else if (this.surveyV3ProactiveVisible && this.hasFooterShortcuts) {
        extraHeight += 100;
      }
      return extraHeight;
    },
    bodyWidgetStyle() {
      return {
        top: this.headerWidgetHeight + "px",
        height: "calc(100dvh - " + this.bodyWidgetsSpaceToSubstract + "px)",
        "padding-top": this.showUpsell ? "0" : "16px"
      };
    },
    footerWidgetStyle() {
      return {
        height: this.footerWidgetHeight + "px",
        width: "100%",
        position: "fixed",
        bottom: this.surveyV3ProactiveVisible && this.hasFooterBubble ? "80px" : 0
      };
    },
    isCMSDraft() {
      return this.$router.currentRoute.value.query.mode === "cms" && this.isDraft;
    },
    isDraft() {
      return this.$router.currentRoute.value.query.draft === "true";
    },
    showSquareBorders() {
      return this.headerWidget?.showSquareBorders;
    },
    frontSideMenuAvailable() {
      return this.frontpageData?.frontSideMenu?.showSideMenu && this.frontSideMenuInteractive;
    },
    scrollDisabled() {
      return this.profileVisible || this.frontSideMenuVisible;
    },
    showFixedHeader() {
      return this.headerWidget && !this.isCMSDraft && this.fixedHeaderInteractive;
    },
    storedScroll() {
      return this.$store.state.frontpageScroll;
    },
    congressEvents() {
      return this.$store.state.congressEvents;
    },
    showMyEvent() {
      return Object.keys(this.congressEvents)?.length > 0;
    },
    myEventWidgets() {
      if (!this.showMyEvent) {
        return null;
      }
      const events = [];
      Object.keys(this.congressEvents).forEach(key => {
        const event = this.congressEvents[key];
        events.push({
          id: key,
          backgroundColor: "#ffffff",
          ctaText: { en: "Enter", es: "Entrar" },
          mainColor: "#c2e3d4",
          photographs: event.photographs,
          place: 1,
          style: 1,
          widgetTitleOptions: { title: event.translatableName },
          active: true,
          templateId: 14 // BodyWidgetLinkedCongress
        });
      });
      return events;
    }
  },
  created() {
    this.getDateNowAndStrings();
    this.createVisitorIfNeeded();
    this.showProfileIfQueryParameter();
    this.showSideMenuIfQueryParameter();
    EventBus.$emit("getUserActivityAndInbox");
    this.checkEnableEnquiries();
  },
  mounted() {
    if (this.$refs.main) {
      this.$refs.main.addEventListener("scroll", this.onScroll);
      if (this.storedScroll > 0) {
        if (this.storedScroll > SHOW_FIXED_HEADER_THRESHOLD) {
          this.avoidAnimateHeader = true;
          this.fixedHeaderInteractive = true;
          this.fixedHeaderVisible = true;
        }
        this.$refs.main.scrollTop = this.storedScroll;
        setTimeout(() => {
          this.avoidAnimateHeader = false;
        }, 200);
      }
    }
    if (this.$store.state.surveysFrontpageShow !== true) {
      this.$store.commit(types.SURVEY_SHOW_FRONTPAGE, true);
    }
  },
  unmounted() {
    if (this.$refs.main) {
      this.$refs.main.removeEventListener("scroll", this.onScroll);
    }
  },
  methods: {
    async getFrontpageData() {
      this.loading = true;
      const response = await this.$store.dispatch("frontpage/getFrontpage", this.isDraft);
      if (response) {
        this.setDefaults(response);
      }
      this.loading = false;
    },
    getBackgroundImage: getImageUrl,
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    setDefaults(response) {
      if (this.$store.state.mainColor !== `#${response.mainColor.replace("#", "")}`) {
        this.$store.commit(types.UPDATE_MAIN_COLOR, response.mainColor ?? "ffffff");
      }
      const url_string = window.location.href;
      const url = new URL(url_string);
      if (this.$store.state.frontpageIcon !== response?.headerWidget?.frontpageIcon || url.searchParams.get("icon")) {
        this.$store.commit(types.FRONTPAGE_ICON, response?.headerWidget?.frontpageIcon ?? 0);
      }
      const html = document.querySelector("html").style;
      html.setProperty("--main-color", this.$store.state.mainColor);
    },
    setHeaderWidgetHeight(height) {
      this.headerWidgetHeight = height;
      this.setBodyWidgetsSpaceToSubstract();
    },
    setFooterWidgetBounds(bounds) {
      this.footerWidgetIsTransparent = bounds.isTransparent;
      this.footerWidgetHeight = bounds.height;
      this.setBodyWidgetsSpaceToSubstract();
    },
    setBodyWidgetsSpaceToSubstract() {
      this.bodyWidgetsSpaceToSubstract = this.headerWidgetHeight + BODY_PADDING_TOP;
      if (this.showUpsell) {
        this.bodyWidgetsSpaceToSubstract -= BODY_PADDING_TOP;
      }
      if (!this.footerWidgetIsTransparent) {
        this.bodyWidgetsSpaceToSubstract += this.footerWidgetHeight;
      }
    },
    actionAll(payload) {
      this.storeScroll();
      this.itemClicked(payload.widget);
    },
    action(payload) {
      this.storeScroll();
      this.itemClicked(payload);
    },
    ctaAction(payload) {
      this.storeScroll();
      this.ctaClicked(payload);
    },
    actionAndHideFrontSideMenu(payload) {
      this.hideFrontSideMenu();
      setTimeout(() => {
        this.storeScroll();
        this.itemClicked(payload);
      }, 100);
    },
    showUserProfile() {
      this.profileInteractive = true;
      setTimeout(() => {
        this.profileVisible = true;
      }, 100);
    },
    showFrontSideMenu() {
      this.frontSideMenuInteractive = true;
      setTimeout(() => {
        this.frontSideMenuVisible = true;
      }, 100);
    },
    showUserRoute() {
      this.showProfilePopup = false;
      if (this.userRouteAfterProfilePopup) {
        this.$router.push(this.userRouteAfterProfilePopup);
      }
    },
    shortcutTapped(shortcut) {
      this.storeScroll();
      this.itemClicked(shortcut.data);
    },
    onScroll(event) {
      const scroll = event.srcElement.scrollTop;
      this.scroll = scroll;
      this.fixedHeaderInteractive = scroll > SHOW_FIXED_HEADER_THRESHOLD;
      if (this.fixedHeaderInteractive) {
        setTimeout(() => {
          this.fixedHeaderVisible = true;
        }, 100);
      } else {
        this.fixedHeaderVisible = false;
      }
    },
    async getDateNowAndStrings() {
      // We only get server date and strings at most once per hour
      if (!this.dateNow?.timestamp || this.dateNow.timestamp + ONE_HOUR < Date.now() / 1000) {
        this.$store.dispatch("getDateNow");
        this.$store.dispatch("getStrings");
      }
    },
    queryIdDifferentThanEstablishmentHash() {
      return (
        this.establishmentHash &&
        this.$router.currentRoute.value.query.id &&
        this.establishmentHash !== this.$router.currentRoute.value.query.id
      );
    },
    async createVisitorIfNeeded() {
      // We only create visitor if no userkey or if establishment changed
      if (!this.user?.userKey || this.queryIdDifferentThanEstablishmentHash()) {
        this.$store.dispatch("createVisitor");
      }
    },
    showProfileIfQueryParameter() {
      if (this.$router.currentRoute.value.query.showProfile && this.$router.currentRoute.value.query.mode === "cms") {
        this.profileVisible = true;
        this.profileInteractive = true;
      }
    },
    showSideMenuIfQueryParameter() {
      if (this.$router.currentRoute.value.query.showSideMenu && this.$router.currentRoute.value.query.mode === "cms") {
        this.frontSideMenuVisible = true;
        this.frontSideMenuInteractive = true;
      }
    },
    hideProfile() {
      this.profileVisible = false;
    },
    hideFrontSideMenu() {
      this.frontSideMenuVisible = false;
    },
    selectLanguage() {
      this.showProfilePopup = true;
    },
    showActivity(activity) {
      this.showTransaction(activity);
    },
    showInbox() {
      this.showRoute("/inbox");
    },
    showLogin() {
      this.showRoute("/login");
    },
    showAllActivity() {
      this.showRoute("/profile-all-activity");
    },
    showLegalTexts() {
      this.showRoute("/profile-legal-links");
    },
    showRoute(path) {
      this.storeScroll();
      this.$router.push({ path: path, query: { id: this.establishmentHash } });
    },
    storeScroll() {
      this.$store.commit(types.STORE_FRONTPAGE_SCROLL, this.scroll);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../assets/styleguide.less";
.widgets-front-main-component {
  height: 100dvh;
  overflow: scroll;
  touch-action: auto;
  &.disable {
    overflow: hidden;
    touch-action: none;
  }
  @media screen and (min-width: 1000px) {
    overflow: hidden;
    touch-action: none;
    pointer-events: none;
  }
}
.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.widgets-front-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  background-color: white;
}
.mini-header {
  position: fixed;
  top: -81px;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 13;
  transition: top 0.1s ease;
  &.show {
    top: 0;
  }
  &.avoid-animate {
    transition: none;
  }
  @media screen and (min-width: 1000px) {
    opacity: 0;
    width: inherit;
  }
}
.frontpage-header-widget {
  width: 100%;
  z-index: 1;
}
.frontpage-body-widgets {
  width: 100%;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}
.frontpage-footer-widget {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  overflow: hidden;
  @media screen and (min-width: 1000px) {
    display: none;
  }
}
.profile-side-menu-fade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  z-index: 10003;
}
.profile-side-menu {
  width: 100vw;
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 10004;
  transition: right ease-in-out 0.1s;
  &.visible {
    right: 0;
  }
}
.front-side-menu {
  width: 100vw;
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 10004;
  transition: left ease-in-out 0.1s;
  &.visible {
    left: 0;
  }
}
.draft-banner {
  position: sticky;
  left: 0;
  top: 0;
  width: inherit;
  height: 12px;
  background: #e98179;
  z-index: 14;
}
.draft-label {
  width: fit-content;
  padding: 2px 20px 2px 20px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  .caption-white;
  background: #e98179;
  border-radius: 12px;
}
</style>
