<template>
  <div class="body-widget-bubble-card-wrapper" @click="action">
    <img class="body-widget-bubble-card__image" :src="getBackgroundImage(image, true)" />
    <div class="body-widget-bubble-card__content__title" :style="{ color: textColor }">
      {{ title }}
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
import { cardStyles } from "@/utils/enums/widgetsFrontpage.js";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {
      cardStyles
    };
  },
  computed: {
    title() {
      return translate(this.card.translatableName);
    },
    image() {
      return this.card.photographs ? this.card.photographs[0] : null;
    },
    textColor() {
      return this.frontpageTextColor || "#34323D";
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-bubble-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  flex: 100px 0 0;
  height: 118px;
  position: relative;
}
.body-widget-bubble-card__image {
  margin: 8px 4px 0 4px;
  height: 64px;
  width: 64px;
  flex: 64px 0 0;
  border-radius: 32px;
  object-fit: cover;
}
.body-widget-bubble-card__content__title {
  .subheader-black;
  text-align: center;
  margin-top: 8px;
}
</style>
