import {
  enterConversation,
  getActiveConversations,
  getUnreadMessages,
  getUsersInConversation,
  initChatClient,
  leaveGuestConversations
} from "../services/chatController";
import { capitalized, findService, getServiceType, isLogged, logVirtualPage } from "../services/utils";
import { EventBus } from "../services/eventBus";
import { mapActions, mapState } from "vuex";
import mixin from "@/services/mixin";
import moment from "moment-timezone";
import * as types from "../store/mutation-types";
import {
  checkIsMessage,
  checkIsObject,
  checkMessageIsClient,
  isChangeInBookingNotifiable,
  isChangeInRequestNotifiable
} from "../services/utilsRequest";
import cloneDeep from "lodash-es/cloneDeep";

const Mixin = {
  data() {
    return {
      hideNotifications: false,
      hasNotifications: false,
      isInitiatingChat: false
    };
  },
  computed: {
    ...mapState(["user", "establishment", "isDesktop", "notifications", "services"])
  },
  mixins: [mixin],
  props: {},
  methods: {
    ...mapActions(["getService"]),
    messageAdded(event) {
      const message = event.detail;
      const showDesktopChatScreen = this.$store.state.showDesktopChatScreen;
      if (this.$router.currentRoute.value.path.indexOf("/chat") !== -1) {
        const event = new CustomEvent("messageReceived", { detail: message.conversation });
        dispatchEvent(event);
      } else if (
        this.isDesktop &&
        this.$router.currentRoute.value.fullPath.indexOf("/desktop/?id=") !== -1 &&
        showDesktopChatScreen
      ) {
        //check front route and the opened panel
        const event = new CustomEvent("messageReceived", { detail: message.conversation });
        dispatchEvent(event);
      } else {
        const currentEstablishment = this.agentMessageFromThisComplex(message);
        if (currentEstablishment || message.from === "BOT") {
          this.showNotification(message);
        }
      }
    },
    agentMessageFromThisComplex(message) {
      if (message.conversation && message.conversation.uniqueName) {
        //its valid for -system- and -cmsUserXX-
        const name = message.conversation.uniqueName;
        const components = name.split("-");
        if (components[2] + "" === this.establishment.id + "") {
          return true;
        }
      }

      return false;
    },
    showNotification(message) {
      if (!message.from || !message.text || !message.conversation) {
        return;
      }

      const notificationsArray = [];
      notificationsArray.push({
        type: "chat",
        data: {
          id: message.sid,
          title: "Message from " + message.from,
          description: message.text,
          conversationName: message.conversation.uniqueName
        }
      });
      this.hideNotifications = false;

      this.$store.commit(types.CHANGE_NOTIFICATIONS, notificationsArray);
    },
    initChatClient(fromEvent) {
      if (this.isInitiatingChat) {
        return;
      }
      //previous initChatClient when it was called from App.vue
      if (!this.user || !this.user.userKey) {
        if (!fromEvent) {
          addEventListener("visitorCreated", this.initChatClient);
        }
        return;
      }
      if (!fromEvent) {
        removeEventListener("visitorCreated", this.initChatClient);
      }

      const tokenData = Object.assign({}, this.user);
      tokenData.establishmentName = this.establishment.name;
      tokenData.establishmentPhotographs = this.establishment.photographs;
      tokenData.establishments = this.user.establishments;
      tokenData.appId = this.user.appId;
      this.isInitiatingChat = true;
      initChatClient(tokenData)
        .then(client => {
          this.$store.commit("USER_USED_CHAT", true);

          //only if bot is enabled
          enterConversation(this.user.uid + "-system-" + this.establishment.id)
            .then(conversation => {
              this.launchInitializedClient();
            })
            .catch(() => {
              this.launchInitializedClient();
            });
          if (!fromEvent) {
            this.checkingConversations();
          }
        })
        .catch(error => {
          this.isInitiatingChat = false;
        });
    },
    checkingConversations() {
      const agentsList = "agents-channel-" + this.establishment.id;
      getUsersInConversation(agentsList).then(() => {
        this.getConversations();
      });
    },
    launchInitializedClient() {
      this.isInitiatingChat = false;
      const event = new Event("chatClientInitiated");
      dispatchEvent(event);
    },
    openNotification(notification) {
      this.hideNotifications = true;

      const actions = {
        promotion: () => {
          this.$store.commit(types.DELETE_NOTIFICATION, notification.data.id);
          this.$router.push({
            name: "UpSellNotificationScreenView",
            params: { idPromo: notification.data.data.promotionId }
          });

          return true;
        },
        alert: () => {
          const alertId = notification.data.id;

          if (this.isDesktop) {
            EventBus.$emit("showAlertDetail", alertId);
            return true;
          }

          this.$router.push("/alert-detail/" + alertId);
          return true;
        },
        request_chat: () => {
          EventBus.$emit("enquiriesButtonAction", { itemType: 15, itemId: this.establishment.id });
          return false;
        },
        survey: () => {
          EventBus.$emit("surveyButtonAction", notification.data);
          return true;
        },
        survey_chat: () => {
          this.$router.push({
            name: "SurveyChat",
            state: {
              params: cloneDeep({
                surveyData: notification.survey
              })
            },
            query: { id: this.$store.state.establishmentHash }
          });
          return false;
        },
        lead: () => {
          const objectName = "requestId=";
          const url =
            "/transaction-confirmation?" +
            objectName +
            notification.data.id +
            "&serviceId=" +
            notification.serviceId +
            "&imageId=" +
            notification.data.image +
            "&id=" +
            this.$store.state.establishmentHash;
          this.$router.push(url);
        },
        booking: () => {
          const objectName = "bookingId=";
          const url =
            "/transaction-confirmation?" +
            objectName +
            notification.data.id +
            "&serviceId=" +
            notification.serviceId +
            "&imageId=" +
            notification.data.image +
            "&id=" +
            this.$store.state.establishmentHash;
          this.$router.push(url);
        },
        order: () => {
          const serviceType = getServiceType(notification.serviceType);
          const serviceId = notification.serviceId;
          const serviceFound = findService(parseInt(serviceId), serviceType, this.services);
          const params = {
            itemType: 3,
            serviceType: serviceType,
            id: serviceId,
            imageId: "0"
          };
          if (!serviceFound) {
            return this.$router.push({
              path: path,
              params: params,
              name: "ServiceDetail",
              state: { params: cloneDeep(params) },
              query: { id: this.establishmentHash }
            });
          }
          const bookingItemCurrency = serviceFound?.currencyData ?? "";
          const path = "/spaces-orders/";
          this.$router.push({
            path: path,
            state: {
              params: cloneDeep({
                service: serviceFound,
                serviceType: serviceType,
                serviceId: serviceId,
                currencyDataProp: bookingItemCurrency
              })
            },
            name: "SpacesOrders"
          });
        },
        default: () => {
          if (this.isDesktop) {
            EventBus.$emit("showGuestPanel");
            return false;
          }

          this.$router.push("/guest-panel");
          return false;
        }
      };
      if (notification.bookingEnabled === 6 && notification.hasOwnProperty("spaceOrderStatus")) {
        notification.type = "order";
      }
      const dismissAction = (actions[notification.type] ?? actions.default)();

      return this.$store.commit(
        dismissAction ? types.DISMISS_NOTIFICATION : types.DELETE_NOTIFICATION,
        notification.data.id
      );
    },
    closeNotification: function (notification) {
      this.hideNotifications = true;
      if (notification.type !== "surveys_chat") {
        this.$store.commit(types.DISMISS_NOTIFICATION, notification.data.id);
      } else {
        this.$store.commit(types.DELETE_NOTIFICATION, notification.data.id);
      }
    },
    openMultipleNotifications: function () {
      if (this.isDesktop) {
        EventBus.$emit("showInbox");
      } else {
        this.$router.push("/inbox");
      }
      this.$store.commit(types.DISMISS_NOTIFICATIONS);
      this.hideNotifications = true;
    },
    dismissAllNotifications: function () {
      this.$store.commit(types.DISMISS_NOTIFICATIONS);
      this.hideNotifications = true;
    },
    updateNotificationsSync: async function (response) {
      const notificationsArray = Object.assign(this.notifications, []);
      const locale = this.getLocale();

      if (response?.bookings?.length > 0) {
        await this.manageBookingNotifications(response.bookings, notificationsArray, locale);
      }
      if (response?.requests?.length) {
        await this.manageRequestNotifications(response.requests, notificationsArray, locale);
      }
      if (response?.alerts && Object.values(response.alerts).length) {
        this.manageAlertsNotifications(response.alerts, notificationsArray);
      }

      this.hideNotifications = false;
      this.$store.commit(types.CHANGE_NOTIFICATIONS, notificationsArray);
      this.logGTMNotificationsIfPresent();
    },
    async manageBookingNotifications(responseBookings, notificationsArray, locale) {
      responseBookings.forEach(async booking => {
        if (isChangeInBookingNotifiable(booking) && !checkMessageIsClient(booking)) {
          let descriptionStatus = "";
          let comment = "";
          if (booking.hasOwnProperty("serviceComment") && booking.serviceComment.length) {
            if (checkIsMessage(booking)) {
              comment = booking.serviceComment;
            }
          }

          if (booking.hasOwnProperty("bookingDate")) {
            descriptionStatus = capitalized(moment(booking.bookingDate).locale(locale).format("dddd DD"));
          }

          if (booking.hasOwnProperty("bookingHour")) {
            descriptionStatus =
              descriptionStatus + ", " + this.getString("AT") + " " + booking.bookingHour.substring(0, 5);
          }
          if (booking.totalPax) {
            descriptionStatus = descriptionStatus + " - " + booking.totalPax + this.getString("PAX");
          }

          const service = await this.getService({
            serviceType: "",
            serviceId: booking.service
          });
          if (service?.id) {
            let spaceOrderStatus = undefined;
            if (
              booking &&
              booking.customData &&
              booking.customData.bookingSpace &&
              booking.customData.bookingSpace.hasOwnProperty("orderStatus")
            ) {
              spaceOrderStatus = booking.customData.bookingSpace.orderStatus;
            }
            const bookingEnabled = booking?.customData?.bookingSpace?.bookingEnabled;
            notificationsArray.push({
              type: "booking",
              status: booking.status,
              comment: comment,
              serviceType: service.type,
              serviceId: booking.service,
              bookingEnabled: bookingEnabled,
              spaceOrderStatus: spaceOrderStatus,
              data: {
                id: booking.id,
                title: this.translate(service.translatableName, this.user),
                description: descriptionStatus,
                image: service.photographs && service.photographs.length ? service.photographs[0] : null
              }
            });
          }
        }
      });
    },
    async manageRequestNotifications(responseRequests, notificationsArray, locale) {
      responseRequests.forEach(async booking => {
        if (isChangeInRequestNotifiable(booking) && !checkMessageIsClient(booking)) {
          let descriptionStatus = "";
          let comment = "";
          if (booking.hasOwnProperty("statusComment") && booking.statusComment.length > 0) {
            if (checkIsMessage(booking)) {
              comment = booking.statusComment;
            }
          }

          const service = await this.getService({
            serviceType: "",
            serviceId: booking.service
          });
          if (service?.id) {
            if (booking.hasOwnProperty("creationDatetime")) {
              descriptionStatus = capitalized(
                moment(booking.creationDatetime.substring(0, 10)).locale(locale).format("dddd DD")
              );
            }
            if (booking.customData.hasOwnProperty("productNames")) {
              descriptionStatus =
                descriptionStatus + " - " + this.translate(booking.customData.productNames, this.user);
            }

            let type = "lead";
            let title = this.translate(service.translatableName, this.user);
            if (booking.cmsModule === 97) {
              type = "request_chat";
              title = "Concierge Chat";
              descriptionStatus = this.establishment.name;
              if (checkIsObject(booking)) {
                comment = this.getString("STAFF_INFORMATION_RECEIVED");
              }
            }
            notificationsArray.push({
              type: type,
              status: booking.status,
              comment: comment,
              serviceType: service.type,
              serviceId: service.id,
              data: {
                id: booking.id,
                title: title,
                description: descriptionStatus,
                image: service.photographs && service.photographs.length ? service.photographs[0] : null
              }
            });
          }
        }
      });
    },
    manageAlertsNotifications(responseAlerts, notificationsArray) {
      Object.values(responseAlerts).forEach(alert => {
        if (alert.trigger !== "now") {
          return;
        }

        const notification = {
          type: alert.alertType === "promotion" ? alert.alertType : "alert",
          data: {
            id: alert.id,
            title: this.translate(alert.alertTitle, this.user),
            description: this.translate(alert.alertDescription, this.user)
          }
        };

        if (alert.alertType === "promotion") {
          notification.data.data = alert;
        }

        notificationsArray.push(notification);
      });
    },
    logGTMNotificationsIfPresent() {
      if (this.notifications?.length) {
        logVirtualPage("/vp/alert-banner", "frontpage", "notifications");
      }
    },

    guestInvited(event) {
      if (!event.detail) return;

      const conversationName = event.detail.conversationName;
      const cliId = event.detail.cliId;
      const agent = event.detail.agent;
      if (this.user.cliId + "" === cliId + "" && isLogged(this.user)) {
        enterConversation(conversationName, this.user, agent)
          .then(conversation => {
            if (getUnreadMessages(conversation) > 0) {
              this.showNotification({
                sid: 0,
                from: this.establishment.name,
                conversation: {},
                text: "You have new chat messages"
              });
            }
          })
          .catch(() => {});
      }
    },
    userLoggedIn() {
      const agentsList = "agents-channel-" + this.establishment.id;
      getUsersInConversation(agentsList);
    },
    userLoggedOut() {
      leaveGuestConversations(this.establishment.id);
      this.$store.dispatch("promotions/resetState");
    },
    getConversations() {
      let anyUnreadMessage = false;
      getActiveConversations()
        .then(conversations => {
          if (!isLogged(this.user)) {
            leaveGuestConversations(this.establishment.id);
          } else {
            conversations.forEach(conversation => {
              if (getUnreadMessages(conversation) > 0) {
                anyUnreadMessage = true;
              }
            });
            if (anyUnreadMessage) {
              this.showNotification({
                sid: 0,
                from: this.establishment.name,
                conversation: {},
                text: "You have new chat messages"
              });
            }
          }
        })
        .catch(error => {
          console.error("Error getting conversations", error.message);
        });
    },
    getLocale() {
      if (this.$store.state.interfaceLanguage) {
        return this.$store.state.interfaceLanguage;
      }
      if (window.navigator.language) {
        return window.navigator.language.substring(0, 2);
      }
      if (this.user.locale) {
        return this.user.locale;
      }
      return "en";
    }
  },
  watch: {
    notifications: function () {
      this.hideNotifications = false;
    }
  }
};

export default Mixin;
