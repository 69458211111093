<template>
  <div class="profile-all-activity">
    <stay-top-bars :title="getString('MY_ACTIVITY')" :left-action="leftActionTopBar" :right-actions="[]" />

    <div class="profile-all-activity__wrapper">
      <template v-if="upcomingActivity?.length">
        <h2 class="profile-all-activity__title">{{ getString("ONGOING_ACTIVITY") }}</h2>
        <profile-activity-card
          v-for="activity in upcomingActivity"
          :key="'activity-' + activity.id"
          :activity="activity"
          @click="showTransaction(activity)"
        />
      </template>

      <template v-if="pastActivity?.length">
        <h2 class="profile-all-activity__title">{{ getString("PAST_ACTIVITY") }}</h2>
        <profile-activity-card
          v-for="activity in pastActivity"
          :key="'activity-' + activity.id"
          :activity="activity"
          @click="showTransaction(activity)"
        />
      </template>
    </div>

    <div v-if="loading" class="loading-container">
      <div class="loader" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { getModuleName, getString, serviceTypes, translate } from "@/services/utils.js";
import moment from "moment-timezone";
import widgetsFrontpageMixin from "@/mixins/widgetsFrontpageMixin.js";
import cloneDeep from "lodash-es/cloneDeep";

const ProfileActivityCard = defineAsyncComponent(
  () => import("@/components/WidgetsFrontpage/Cards/ProfileActivityCard.vue")
);
const StayTopBars = defineAsyncComponent(() => import("@/components/UiKit/Navigation/TopBars/StayTopBars.vue"));
export default {
  components: {
    ProfileActivityCard,
    StayTopBars
  },
  mixins: [widgetsFrontpageMixin],
  data() {
    return {
      userActivity: [],
      loading: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    establishment() {
      return this.$store.state.establishment;
    },
    strings() {
      return this.$store.state.strings;
    },
    myScheduleObjects() {
      const objects = [];
      const now = new moment();
      const mySchedule = this.$store.state.mySchedule;
      if (mySchedule && mySchedule[this.establishment.id]) {
        Object.keys(mySchedule[this.establishment.id]).forEach(key => {
          const item = mySchedule[this.establishment.id][key];
          item.startDate = item.eventDay;
          item.description = this.translate(item.translatableName);
          item.title = this.getString(getModuleName(item.cmsModule));
          item.serviceType = serviceTypes[item.cmsModule];
          item.serviceId = item.service;
          item.id = ("" + item.id).replace(/:event/g, "") + ":event";
          objects.push(item);
        });
      }
      return objects;
    },
    myVisitorBookings() {
      const objects = [];
      const myVisitorBookings = this.$store.state.myVisitorBookings;
      Object.keys(myVisitorBookings).forEach(key => {
        const item = myVisitorBookings[key];
        item.startDate = item.bookingDate;
        item.hour = item.bookingHour.substring(0, 5);
        item.description = item.serviceName;
        item.title = this.getString(getModuleName(item.cmsModule));
        item.serviceType = serviceTypes[item.cmsModule];
        item.serviceId = item.service;
        item.bookingId = item.id;
        item.id = ("" + item.id).replace(/:booking/g, "") + ":booking";
        objects.push(item);
      });
      return objects;
    },
    surveyReplies() {
      const objects = [];
      const surveyReplies = this.$store.state.surveys?.surveyReplies;
      if (surveyReplies) {
        Object.keys(surveyReplies).forEach(key => {
          const items = surveyReplies[key].filter(
            el =>
              (el.hasOwnProperty("unreadStaffMessage") && el.unreadStaffMessage === true) ||
              (el.hasOwnProperty("unreadStaffMessage") && el.hasOwnProperty("unreadGuestMessage"))
          );
          items.forEach(item => {
            item.type = "surveys";
            item.startDate = moment(item.repliedOn).format("YYYY-MM-DD");
            item.hour = moment(item.repliedOn).format("HH:mm");
          });
          objects.push(...items);
        });
      }
      return objects;
    },
    allActivity() {
      const allActivity = [];
      if (this.userActivity?.length > 0) {
        allActivity.push(...this.userActivity);
      }
      if (this.myScheduleObjects?.length > 0) {
        allActivity.push(...this.myScheduleObjects);
      }
      if (this.myVisitorBookings?.length > 0) {
        allActivity.push(...this.myVisitorBookings);
      }
      if (this.surveyReplies?.length > 0) {
        allActivity.push(...this.surveyReplies);
      }
      allActivity.sort((b, a) => {
        return (
          parseInt(b?.startDate?.replace(/-/g, "") + b?.hour?.replace(":", "")) -
          parseInt(a?.startDate?.replace(/-/g, "") + a?.hour?.replace(":", ""))
        );
      });
      return allActivity;
    },
    upcomingActivity() {
      const now = new moment();
      return this.allActivity.filter(item => {
        const activityMoment = moment(item.startDate + " " + item.hour);
        return now.isBefore(activityMoment);
      });
    },
    pastActivity() {
      const now = new moment();
      const pastActivity = cloneDeep(
        this.allActivity.filter(item => {
          const activityMoment = moment(item.startDate + " " + item.hour);
          return !now.isBefore(activityMoment);
        })
      );
      return pastActivity.reverse();
    },
    leftActionTopBar() {
      return { icon: "white-topbar/Back", action: () => this.$router.go(-1) };
    }
  },
  created() {
    this.getUserActivity();
  },
  methods: {
    translate,
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    async getUserActivity() {
      this.userActivity = [];
      if (this.user && this.user.cliId) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch("getActivity");
          if (response) {
            if (response?.length > 0) {
              this.userActivity.push(...response);
            }
          }
        } catch (error) {
          console.error("Error getting user activity", error);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";

.profile-all-activity {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;

  * {
    box-sizing: border-box;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: @size-base*2;

    padding: @size-base*4 @size-base*2;
    background-color: @color-bg-page;
  }

  &__title {
    .u-text__headline--5;
    .bold;
    margin: 0;
  }
}

.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.loader {
  border: 3px solid #697181;
  border-radius: 50%;
  border-left-color: #ebedf0;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
