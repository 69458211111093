<template>
  <div
    class="body-widget-slider-card-2-wrapper"
    :class="[showCta ? 'with-cta' : '', showSquareBorders ? 'show-square-borders' : '']"
    @click="action"
  >
    <img
      class="body-widget-slider-card-2__image"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :src="getBackgroundImage(image, '_appthumb', '_appthumb')"
    />
    <div
      v-if="highlightedText"
      class="body-widget-slider-card-2__highlighted-text"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :style="{ 'background-color': highlightColor }"
    >
      {{ highlightedText }}
    </div>
    <div class="body-widget-slider-card-2__content" :class="[showCta ? 'with-cta' : '']">
      <div class="body-widget-slider-card-2__content__title">
        {{ title }}
      </div>
      <div v-if="description" class="body-widget-slider-card-2__content__description">
        {{ description }}
      </div>
      <body-widget-subtitle-wrapper
        v-if="showSubtitle"
        :card-options="cardOptions"
        :card="card"
        :subtitle-color="'black'"
        class="body-widget-slider-card-2__content__subtitle"
      />
      <body-widget-cta-wrapper
        v-if="showCta"
        :card-options="cardOptions"
        class="body-widget-slider-card-2__content__cta"
        @cta-action="ctaAction"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { getImageUrl, translate } from "@/services/utils.js";
import { cardStyles, subtitleColors } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetSubtitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetSubtitleWrapper.vue")
    ),
    BodyWidgetCtaWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetCtaWrapper.vue")
    )
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      default: false
    }
  },
  emits: ["action", "ctaAction"],
  data() {
    return {
      cardStyles
    };
  },
  computed: {
    title() {
      return translate(this.card.translatableName);
    },
    description() {
      return translate(this.card.translatableDescription);
    },
    image() {
      return this.card.photographs ? this.card.photographs[0] : null;
    },
    highlightedText() {
      if (
        !this.card?.highlightedText ||
        typeof this.card.highlightedText !== "object" ||
        this.card.highlightedText.length === 0 ||
        translate(this.card.highlightedText).length === 0
      ) {
        return null;
      }
      return translate(this.card.highlightedText);
    },
    highlightColor() {
      if (!this.cardOptions.highlightColor) {
        return this.$store.state.mainColor;
      }
      return this.cardOptions.highlightColor;
    },
    showSubtitle() {
      return this.cardOptions.showSubtitle && this.cardOptions.subtitleType;
    },
    subtitleColor() {
      if (!this.cardOptions?.subtitleColor) return null;
      return subtitleColors[this.cardOptions.subtitleColor];
    },
    showCta() {
      return this.cardOptions.showCta;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    },
    ctaAction() {
      this.$emit("ctaAction", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-slider-card-2-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 240px;
  flex: 240px 0 0;
  min-height: 251px;
  position: relative;
  border: 1px solid @color-gray-2;
  border-radius: 4px;
  background-color: white;
  &.with-cta {
    height: auto;
  }
}
.body-widget-slider-card-2__image {
  width: 100%;
  height: 140px;
  flex: 140px 0 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  object-fit: cover;
}
.body-widget-slider-card-2__content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 100px 1 0;
}
.body-widget-slider-card-2__content__title {
  .body-black;
  .medium;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
}
.body-widget-slider-card-2__content__description {
  margin-top: 4px;
  .caption-grey;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
}
.body-widget-slider-card-2__content__subtitle {
  margin-top: auto;
}
.body-widget-slider-card-2__highlighted-text {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 4px 8px;
  border-radius: 16px;
  .caption-white;
}
.body-widget-slider-card-2__content__cta {
  margin-top: 8px;
}
</style>
