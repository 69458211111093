<template>
  <div class="body-widget-slider-card-wrapper">
    <component
      :is="getCardComponent()"
      :key="card.id"
      class="body-widget-slider-card"
      :card="card"
      :card-options="widget.cardOptions"
      :extra-info="extraInfo"
      :show-square-borders="showSquareBorders"
      @action="action"
      @cta-action="ctaAction"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { cardOptions } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetSliderCardBG: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetSliderCardBG.vue")
    ),
    BodyWidgetSliderCardBox4_3: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetSliderCardBox4_3.vue")
    ),
    BodyWidgetSliderCardBox1_1: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetSliderCardBox1_1.vue")
    ),
    BodyWidgetSliderListCard: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetSliderListCard.vue")
    ),
    BodyWidgetSliderCardIHA: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetSliderCardIHA.vue")
    ),
    BodyWidgetSliderCardUpcomingActivity: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetSliderCardUpcomingActivity.vue")
    )
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    card: {
      type: Object,
      required: true
    },
    extraInfo: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action", "ctaAction"],
  data() {
    return {};
  },
  methods: {
    getCardComponent() {
      return cardOptions[this.widget.cardOptions.style];
    },
    action(payload) {
      this.$emit("action", payload);
    },
    ctaAction(payload) {
      this.$emit("ctaAction", payload);
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-slider-card-wrapper {
  width: 100%;
}
</style>
