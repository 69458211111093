<template>
  <div class="body-widget-highlights-wrapper">
    <flickity ref="flickity" class="carousel-upsell" :options="getFlickityOptions(children)">
      <div
        v-for="(child, index) in children"
        :key="'highlight-' + index"
        class="body-widget-highlight"
        :class="['child-' + index]"
        :style="{ 'background-color': widget.mainColor, 'min-height': getCardHeight(index) + 180 + 'px' }"
      >
        <img class="body-widget-highlight__image" :src="getImage(child)" />
        <div
          :id="'highlight-card-' + index"
          class="body-widget-highlight__card"
          :class="[showSquareBorders ? 'show-square-borders' : '']"
        >
          <div v-if="getTitle(child)" class="body-widget-highlight__title">{{ getTitle(child) }}</div>
          <div v-if="getDescription(child)" class="body-widget-highlight__description">{{ getDescription(child) }}</div>
          <div
            v-if="getButtonText(child)"
            class="body-widget-highlight__button"
            :class="[showSquareBorders ? 'show-square-borders' : '']"
            :style="{ 'background-color': getButtonColor(child) }"
            @click="action(child, index)"
          >
            <div class="body-widget-highlight__button__text">{{ getButtonText(child) }}</div>
          </div>
        </div>
      </div>
    </flickity>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
import Flickity from "@/components/UiKit/Flickity/Flickity.vue";

export default {
  components: {
    Flickity
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {
      children: []
    };
  },
  created() {
    this.loadItems();
  },
  mounted() {
    this.$forceUpdate();
    setTimeout(() => {
      this.$refs.flickity?.resize();
    }, 200);
  },
  methods: {
    async loadItems() {
      if (this.widget.children?.length > 0) {
        this.children = this.widget.children;
      }
    },
    getImageUrl,
    getImage(child) {
      return child?.photographs ? getImageUrl(child.photographs[0], "_ld", "_ld") : null;
    },
    getTitle(child) {
      return child.translatableName ? translate(child.translatableName) : null;
    },
    getDescription(child) {
      return child.translatableDescription ? translate(child.translatableDescription) : null;
    },
    getButtonText(child) {
      return child.buttonText ? translate(child.buttonText) : null;
    },
    getButtonColor(child) {
      return child.buttonColor || this.$store.state.mainColor;
    },
    getFlickityOptions(children) {
      return {
        prevNextButtons: false,
        dragThreshold: 20,
        autoPlay: false,
        groupCells: true,
        pageDots: false,
        touchVerticalScroll: false,
        wrapAround: false
      };
    },
    getCardHeight(index) {
      const div = document.getElementById("highlight-card-" + index);
      if (!div) {
        return 246;
      }
      return div.offsetHeight;
    },
    action(payload, index) {
      this.$emit("action", { ...payload, ...{ widget: this.widget, index: index } });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.carousel-upsell {
  height: fit-content;
  width: 100%;
  overflow-y: hidden;
}
.flickity-page-dots {
  bottom: 20px;
  height: 10px;
}
.body-widget-highlight {
  width: calc(100% - 80px);
  min-height: 444px;
  height: fit-content;
  margin-right: 16px;
}
.body-widget-highlight__image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.body-widget-highlight__card {
  position: absolute;
  width: calc(100% - 48px);
  min-height: 264px;
  height: auto;
  left: 24px;
  top: 132px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 2px 2px 20px 0 rgba(52, 50, 61, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.body-widget-highlight__title {
  font-size: 28px;
  line-height: 38px;
  color: @main-text;
  margin-top: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
}
.body-widget-highlight__description {
  .subheader-black;
  .medium;
  margin-top: 16px;
  margin-left: 20px;
  margin-bottom: 16px;
  width: calc(100% - 40px);
}
.body-widget-highlight__button {
  width: 127px;
  height: 32px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 0 20px 16px;
}
.body-widget-highlight__button__text {
  .subheader-white;
  .medium;
}
</style>
<style lang="less">
.body-widget-highlights-wrapper {
  .flickity-page-dots {
    bottom: 0px;
  }
}
</style>
