<template>
  <div class="front-side-menu" @click.stop="$emit('hide')">
    <div class="front-side-menu-options" :style="{ 'background-color': backgroundColor }" @click.stop="doNothing">
      <div v-if="showExitButton" class="front-side-menu-options__exit" @click="emitExitEstablishmentEventoToEmbedded">
        <img class="front-side-menu-options__exit__icon" src="../../../assets/dynamic-frontpage/Exit.png" />
        <div class="front-side-menu-options__exit__text h4-white">{{ getString("EXIT") }}</div>
      </div>
      <img
        v-if="sideMenuIcon"
        class="front-side-menu-options__logo"
        :class="{ 'with-exit': showExitButton }"
        :src="getImageUrl(sideMenuIcon, true)"
      />
      <div v-for="(group, indexGroup) in groups" :key="'group-' + indexGroup" class="front-side-menu-options__option">
        <div class="front-side-menu-options__option__title h3-black" :style="{ color: textColor }">
          {{ translate(group.translatableName) }}
        </div>
        <div v-if="group.children?.length > 0">
          <div
            v-for="(section, indexSection) in group.children"
            :key="'section-' + indexSection + '-group-' + indexGroup"
            class="front-side-menu-options__option__section"
            @click="selectedSection(section)"
          >
            <div class="front-side-menu-options__option__section__title h4-black" :style="{ color: textColor }">
              {{ translate(section.translatableName, indexGroup, indexSection) }}
            </div>
            <img src="../../../assets/arrow@2x.png" class="front-side-menu-options__option__section__arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, getString, isFullEmbedPWA, isMonoHotelApp, translate } from "@/services/utils.js";

export default {
  props: {
    frontpageData: {
      type: Object || null,
      required: true
    },
    sideMenuVisible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["hide", "action"],
  computed: {
    groups() {
      return this.frontpageData?.frontSideMenu?.children || [];
    },
    sideMenuIcon() {
      return this.frontpageData?.frontSideMenu?.sideMenuIcon || null;
    },
    backgroundColor() {
      return this.frontpageData?.frontSideMenu?.backgroundColor || this.$store.state.mainColor || "#000000";
    },
    textColor() {
      return this.frontpageData?.frontSideMenu?.textColor || "#ffffff";
    },
    showExitButton() {
      return isFullEmbedPWA() && !isMonoHotelApp();
    },
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    translate,
    getImageUrl,
    doNothing() {},
    selectedSection(section, indexGroup, indexSection) {
      this.$emit("action", { ...section, ...{ indexGroup, indexSection } });
    },
    emitExitEstablishmentEventoToEmbedded() {
      window.location.href = "https://exit-establishment";
    },
    getString(key) {
      return getString(this.strings, key, this.user);
    }
  }
};
</script>

<style lang="less" scoped>
.front-side-menu {
  height: 100%;
  width: 100vw;
}
.front-side-menu-options__exit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  z-index: 1;
  height: 23px;
}
.front-side-menu-options__exit__icon {
  height: 22px;
  width: 22px;
  margin-right: 16px;
  object-fit: contain;
}
.front-side-menu-options__logo {
  height: 52px;
  width: 95px;
  z-index: 2;
  object-fit: contain;
  margin: 8px 0;
  &.with-exit {
    margin-top: 32px;
  }
}
.front-side-menu-options {
  background-color: white;
  max-width: 276px;
  width: calc(100vw - 100px);
  height: calc(100% - 48px);
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  padding: calc(24px + var(--embed-top-safearea)) 24px 24px;
}
.front-side-menu-options__option__title {
  margin-bottom: 8px;
  margin-top: 24px;
}
.front-side-menu-options__option__section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 10px;
}
.front-side-menu-options__option__section__title {
  margin-top: 12px;
}
.front-side-menu-options__option__section__arrow {
  height: 10px;
  width: 6px;
  opacity: 0.5;
  margin-top: 18px;
}
</style>
