<template>
  <div class="body-widget-3-bubbles-container">
    <body-widget-title-wrapper
      v-if="widget?.widgetTitleOptions?.active"
      :widget-title-options="widget.widgetTitleOptions"
      :frontpage-text-color="frontpageTextColor"
      @action-all="actionAll"
    />
    <div class="body-widget-3-bubbles-cards-wrapper">
      <div v-if="loading" class="loading-container">
        <circle-spinner class="circle-spinner" :size="'10px'"></circle-spinner>
      </div>
      <div
        v-for="card in cards.slice(0, maxItems)"
        :key="card.id"
        class="body-widget-3-bubbles-card-item animated fadeIn"
      >
        <body-widget-bubble-card
          :widget="widget"
          :card="card"
          :card-options="widget.cardOptions"
          :extra-info="extraInfo"
          :frontpage-text-color="frontpageTextColor"
          @action="action"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import widgetsFrontpageMixin from "@/mixins/widgetsFrontpageMixin.js";
const MAX_ITEMS = 3;
export default {
  components: {
    CircleSpinner: defineAsyncComponent(() => import("@/components/Loading/CircleSpinner.vue")),
    BodyWidgetTitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetTitleWrapper.vue")
    ),
    BodyWidgetBubbleCard: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetBubbleCard.vue")
    )
  },
  mixins: [widgetsFrontpageMixin],
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    }
  },
  emits: ["action", "actionAll"],
  data() {
    return {
      maxItems: MAX_ITEMS,
      cards: [],
      extraInfo: {},
      loading: false
    };
  },
  created() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      const response = await this.getItemsForWidget(this.widget);
      if (response) {
        this.cards = response;
      }
      this.loading = false;
    },
    actionAll(payload) {
      this.$emit("actionAll", payload);
    },
    action(payload) {
      this.$emit("action", payload);
    }
  }
};
</script>

<style lang="less" scoped>
.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.body-widget-3-bubbles-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: no-wrap;
  padding: 16px 16px 0 16px;
  flex-basis: 100%;
  overflow-x: auto;
  min-height: 142px;
}
.body-widget-3-bubbles-cards-wrapper::-webkit-scrollbar {
  display: none;
}
.body-widget-3-bubbles-card-item {
  margin-bottom: 8px;
}
</style>
