<template>
  <div class="widget-underline4-wrapper">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 375 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Components / Catalogs / Header / Shapes / 02</title>
      <g
        id="Components-/-Catalogs-/-Header-/-Shapes-/-02"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          id="Divider"
          d="M0,10.6485576 C60.140625,-3.5495192 122.71875,-3.5495192 187.734375,10.6485576 C252.75,24.8466344 315.171875,24.8466344 375,10.6485576 L375,24 L0,24 L0,10.6485576 Z"
          :fill="fillColor"
          fill-rule="nonzero"
          transform="translate(187.5, 12) scale(-1, 1) translate(-187.5, -12)"
        ></path>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: "#FFFFFF"
    }
  }
};
</script>

<style></style>
