<template>
  <div class="mini-header">
    <div class="mini-header__headline">{{ headlineText }}</div>
    <div class="header-widget-1-action-buttons">
      <div v-if="showUserButton">
        <div v-if="!isLogged(user)" class="header-widget-1-button" @click="showUserProfile">
          <img
            class="header-widget-1-button-icon"
            src="../../../assets/dynamic-frontpage/header-icons/Black/User.png"
          />
        </div>
        <div v-else class="header-widget-1-button-logged" @click="showUserProfile">
          <img class="header-widget-room-icon" src="../../../assets/dynamic-frontpage/header-icons/Black/Room.png" />
          <div v-if="userRoom" class="header-widget-1-user-room caption-black semibold">{{ userRoom }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogged } from "@/services/utils.js";

export default {
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  emits: ["showUserProfile"],
  computed: {
    user() {
      return this.$store.state.user;
    },
    establishment() {
      return this.$store.state.establishment;
    },
    userName() {
      return this.user?.name;
    },
    userRoom() {
      return this.user?.room;
    },
    showUserButton() {
      return this.widget.showUserButton;
    },
    headlineText() {
      return this.establishment.name;
    }
  },
  methods: {
    isLogged,
    showUserProfile() {
      this.$emit("showUserProfile");
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide";

.mini-header {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: @size-base*4;
  padding-top: calc(var(--embed-top-safearea) + @size-base*4);
  background-color: @color-white;
  box-shadow: 0 16px 32px 0 rgba(52, 50, 61, 0.1);
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &__headline {
    .u-text__headline--4;
  }
}

.header-widget-1-action-buttons {
  display: flex;
  flex-direction: row;
  z-index: 1;
}
.header-widget-1-button {
  height: 48px;
  width: 48px;
  flex: 48px 0 0;
  border: 1px solid rgba(52, 50, 61, 0.4);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header-widget-1-button-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
}
.header-widget-1-button-logged {
  border-radius: 4px;
  height: 48px;
  width: 60px;
  flex-basis: 60px;
  border: 1px solid @color-card-borders;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.header-widget-room-icon {
  width: 16px;
  height: 16px;
}
.header-widget-1-user-room {
  margin-top: 2px;
}
</style>
