<template>
  <div
    class="body-widget-activity-card-wrapper"
    :class="{ 'no-border': hideBottomBorder, 'aligned-to-start': alignedToStart }"
    @click="action"
  >
    <img v-if="image" class="body-widget-activity-card__image" :src="getBackgroundImage(image, true)" />
    <div
      v-if="eventMonth && eventDay"
      class="body-widget-activity-card-event-date"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    >
      <div
        class="body-widget-activity-card-event-date-background"
        :class="[showSquareBorders ? 'show-square-borders' : '']"
        :style="{ 'background-color': mainColor }"
      />
      <div class="body-widget-activity-card-event-date__month caption-black medium">
        {{ eventMonth }}
      </div>
      <div class="body-widget-activity-card-event-date__day body-black medium">
        {{ eventDay }}
      </div>
    </div>
    <div class="body-widget-activity-card__content" :class="{ 'aligned-to-start': alignedToStart }">
      <div v-if="categoryName" class="body-widget-activity-card__content__category caption-grey">
        {{ categoryName }}
      </div>
      <div class="body-widget-activity-card__content__title" :class="titleClass">
        {{ title }}
      </div>
      <div class="body-widget-activity-card-bottom-line">
        <img class="body-widget-subtitle-1__icon time" :src="timeIcon" />
        <div class="body-widget-activity-card__hour caption-black">
          {{ hour }}
        </div>
        <div v-if="locationDescription" class="body-widget-activity-card__content__location">
          <img class="body-widget-subtitle-1__icon location" :src="pinIcon" />
          <div class="body-widget-subtitle-1__text caption-black">
            {{ locationDescription }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
import { cardStyles } from "@/utils/enums/widgetsFrontpage.js";
import pinWhite from "@/assets/Pin@2x.png";
import pinGrey from "@/assets/ic-grey-pin@2x.png";
import timeGrey from "@/assets/dynamic-frontpage/cards-icons/time-grey.png";
import moment from "moment";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: false
    },
    hideBottomBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    showSquareBorders: {
      type: Boolean,
      required: false,
      default: false
    },
    titleClass: {
      type: String,
      required: false,
      default: "subheader-black medium"
    },
    alignedToStart: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["action"],
  data() {
    return {
      cardStyles,
      pinIcon: pinGrey,
      timeIcon: timeGrey
    };
  },
  computed: {
    title() {
      return translate(this.card.translatableName);
    },
    hour() {
      return this.card.hour.substring(0, 5);
    },
    image() {
      return this.cardOptions?.showImage && this.card.photographs ? this.card.photographs[0] : null;
    },
    locationDescription() {
      if (!this.card.place?.serviceName) return null;
      return translate(this.card.place.serviceName);
    },
    eventMonth() {
      if (!this.card.eventDay) return null;
      return moment(this.card.eventDay).locale(window.navigator.language).format("MMM");
    },
    eventDay() {
      if (!this.card.eventDay) return null;
      return moment(this.card.eventDay).format("DD");
    },
    categoryName() {
      if (!this.card?.calendar) return null;
      return translate(this.card.calendar.categoryName);
    },
    mainColor() {
      return this.$store.state.mainColor;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-activity-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 84px 1 0;
  min-height: 84px;
  position: relative;
  border-bottom: 1px solid @color-gray-2;
  &.no-border {
    border-bottom: none;
  }
  &.aligned-to-start {
    flex: auto 0 0;
    min-height: auto;
    align-items: flex-start;
    padding-top: 8px;
  }
}
.body-widget-activity-card__image {
  margin: 8px 4px 0 4px;
  height: 64px;
  width: 64px;
  flex: 64px 0 0;
  border-radius: 32px;
  object-fit: cover;
}
.body-widget-activity-card-event-date {
  height: 65px;
  width: 50px;
  flex: 50px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.body-widget-activity-card-event-date-background {
  position: absolute;
  height: 65px;
  width: 50px;
  opacity: 0.4;
  left: 0;
  top: 8px;
  border-radius: 4px;
}
.body-widget-activity-card-event-date__month::first-letter {
  text-transform: uppercase;
}
.body-widget-activity-card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 16px;
  &.aligned-to-start {
    margin-top: 8px;
  }
}
.body-widget-activity-card__content__title {
  text-align: center;
}
.body-widget-activity-card-bottom-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
}
.body-widget-activity-card__hour {
  margin-left: 4px;
}
.body-widget-activity-card__content__location {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 1px solid #d8dce5;
  padding-left: 16px;
  margin-left: 16px;
}
.body-widget-subtitle-1__icon {
  height: 13px;
  &.time {
    width: 13px;
  }
  &.location {
    width: 11px;
  }
}
.body-widget-subtitle-1__text {
  margin-left: 8px;
}
</style>
