<template>
  <div class="body-widget-placeholder-1" :style="{ height: height + 'px' || 'auto' }">
    <img :src="icon" class="body-widget-placeholder-1-content-icon" :style="{ height: imageHeight + 'px' || 'auto' }" />
    <div class="body-widget-placeholder-1-content-title caption-black">{{ title }}</div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    icon() {
      return defineAsyncComponent(() => import(/* @vite-ignore */ `../../../assets/${this.iconName}`));
    },
    imageHeight() {
      if (!this.height || this.height === 0) return this.height || 100;
      return this.height - 20;
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-placeholder-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 16px);
}
</style>
