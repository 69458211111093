<template>
  <div class="body-widget-category-children-container">
    <body-widget-title-wrapper
      v-if="widget?.widgetTitleOptions?.active"
      :widget-title-options="widget.widgetTitleOptions"
      :frontpage-text-color="frontpageTextColor"
      @action-all="actionAll"
    />
    <div class="body-widget-category-children-cards-wrapper">
      <div v-if="loading" class="loading-container">
        <div class="loader" />
      </div>
      <div
        v-for="(card, index) in cards.slice(0, maxItems)"
        :key="card.id"
        class="body-widget-category-children-card-item"
        :style="{
          width: sizeForIndex(index, cards.length).width,
          flex: sizeForIndex(index, cards.length).width + '0 0'
        }"
      >
        <body-widget-category-with-children-card
          :widget="widget"
          :card="card"
          :card-options="widget.cardOptions"
          :extra-info="extraInfo"
          :font-size="sizeForIndex(index, cards.length).fontSize"
          :show-square-borders="showSquareBorders"
          :image-size="imageSizeForIndex(index, cards.length)"
          @action="action(card, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import widgetsFrontpageMixin from "@/mixins/widgetsFrontpageMixin.js";
const FULL_WIDTH = "calc(100%)";
const HALF_WIDTH = "calc(50% - 4px)";
const FULL_WIDTH_FONT_SIZE = 20;
const HALF_WIDTH_FONT_SIZE = 16;
const MAX_ITEMS = 5;
const IMAGE_SIZE_FULL_WIDTH = "_ld";
const IMAGE_SIZE_HALF_WIDTH = "_appthumb";
export default {
  components: {
    BodyWidgetTitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetTitleWrapper.vue")
    ),
    BodyWidgetCategoryWithChildrenCard: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetCategoryWithChildrenCard.vue")
    )
  },
  mixins: [widgetsFrontpageMixin],
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action", "actionAll"],
  data() {
    return {
      maxItems: MAX_ITEMS,
      cards: [],
      extraInfo: {},
      loading: false
    };
  },
  watch: {
    widget: {
      handler() {
        this.loadItems();
      },
      deep: true
    }
  },
  created() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      if (this.widget.children?.length > 0) {
        this.cards = this.widget.children;
      }
      this.loading = false;
    },
    actionAll(payload) {
      this.$emit("actionAll", { ...payload, ...{ widget: this.widget } });
    },
    action(payload, index) {
      this.$emit("action", { ...payload, ...{ widget: this.widget, index: index } });
    },
    sizeForIndex(index, numberOfElements) {
      if (numberOfElements === 1 || numberOfElements === 3 || numberOfElements === 4) {
        if (index === 0 || index === 3) {
          return { width: FULL_WIDTH, fontSize: FULL_WIDTH_FONT_SIZE };
        }
        return { width: HALF_WIDTH, fontSize: HALF_WIDTH_FONT_SIZE };
      }
      if (numberOfElements === 2 || numberOfElements >= 5) {
        if (index === 0 || index === 1 || index === 3 || index === 4) {
          return { width: HALF_WIDTH, fontSize: HALF_WIDTH_FONT_SIZE };
        }
        return { width: FULL_WIDTH, fontSize: FULL_WIDTH_FONT_SIZE };
      }
    },
    imageSizeForIndex(index, numberOfElements) {
      if (numberOfElements === 1 || numberOfElements === 3 || numberOfElements === 4) {
        if (index === 0 || index === 3) {
          return IMAGE_SIZE_FULL_WIDTH;
        }
        return IMAGE_SIZE_HALF_WIDTH;
      }
      if (numberOfElements === 2 || numberOfElements >= 5) {
        if (index === 0 || index === 1 || index === 3 || index === 4) {
          return IMAGE_SIZE_HALF_WIDTH;
        }
        return IMAGE_SIZE_FULL_WIDTH;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.loader {
  border: 3px solid #697181;
  border-radius: 50%;
  border-left-color: #ebedf0;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.body-widget-category-children-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 16px 16px 0 16px;
  flex-basis: 100%;
  overflow-x: auto;
  min-height: 156px;
}
.body-widget-category-children-cards-wrapper::-webkit-scrollbar {
  display: none;
}
.body-widget-category-children-card-item {
  margin-bottom: 8px;
}
</style>
