<template>
  <div class="body-widget-button-wrapper">
    <div
      class="body-widget-button"
      :class="[buttonWidthFitsContent ? 'fit' : 'full', showSquareBorders ? 'show-square-borders' : '']"
      :style="{ 'background-color': backgroundColor }"
      @click="action"
    >
      <div class="body-widget-button-text" :style="{ color: textColor }">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import { translate } from "@/services/utils.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {};
  },
  computed: {
    text() {
      if (!this.widget?.widgetTitleOptions?.title) {
        return "";
      }
      return translate(this.widget.widgetTitleOptions.title);
    },
    backgroundColor() {
      if (!this.widget?.backgroundColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.backgroundColor;
    },
    textColor() {
      if (!this.widget?.textColor) {
        return "";
      }
      return this.widget.textColor;
    },
    buttonWidthFitsContent() {
      return this.widget?.buttonWidthFitsContent;
    }
  },
  methods: {
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-button-wrapper {
  width: 100%;
  min-height: 50px;
}
.body-widget-button {
  margin: 12px 16px;
  padding: 13px 24px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.fit {
    width: fit-content;
    margin: 12px auto;
  }
}
.body-widget-button-text {
  .h4-white;
}
</style>
