<template>
  <div class="body-widget-wrapper">
    <div id="top" />
    <slot v-for="widget in widgets">
      <div
        v-if="showWidget(widget)"
        :id="widgets.indexOf(widget)"
        :key="widget.id"
        class="body-widget"
        :class="[getWidgetComponent(widget), forcedVisibleClass]"
      >
        <component
          :is="getWidgetComponent(widget)"
          :widget="widget"
          :frontpage-text-color="frontpageTextColor"
          :show-square-borders="showSquareBorders"
          :scroll="scroll"
          @action="action"
          @action-all="actionAll"
          @cta-action="ctaAction"
        ></component>
      </div>
    </slot>
    <div v-if="extraDivBottomHeight" :style="{ height: extraDivBottomHeight + 'px' }"></div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { bodyWidgets, headerWidgets, widgetPlaces } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetSlider: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetSlider.vue")),
    BodyWidgetCategoryWithChildren: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetCategoryWithChildren.vue")
    ),
    BodyWidget3Bubbles: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidget3Bubbles.vue")
    ),
    BodyWidgetNextActivities: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetNextActivities.vue")
    ),
    BodyWidgetTextSeparator: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetTextSeparator.vue")
    ),
    BodyWidgetBanner: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetBanner.vue")),
    BodyWidgetHighlights: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetHighlights.vue")
    ),
    BodyWidgetButton: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetButton.vue")),
    BodyWidgetShortcuts: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetShortcuts.vue")
    ),
    BodyWidgetUpsell: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetUpsell.vue")),
    BodyWidgetLineSeparator: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetLineSeparator.vue")
    ),
    BodyWidgetVideo: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetVideo.vue")),
    BodyWidgetLinkedCongress: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetLinkedCongress.vue")
    )
  },
  props: {
    widgets: {
      type: Array,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    extraDivBottomHeight: {
      type: Number,
      required: false
    },
    showSquareBorders: {
      type: Boolean,
      required: false,
      default: false
    },
    scroll: {
      type: Number,
      required: false
    }
  },
  emits: ["action", "actionAll", "ctaAction"],
  computed: {
    cmsMode() {
      return this.$store.state.cmsMode;
    },
    isDraft() {
      return this.$router.currentRoute.value.query.draft === "true";
    },
    forcedVisibleClass() {
      return this.cmsMode || this.isDraft ? "visible" : "";
    }
  },
  mounted() {
    this.observeWidgets();
  },
  methods: {
    getWidgetComponent(widget) {
      if (widget.place === widgetPlaces.HEADER) {
        return headerWidgets[widget.templateId];
      }
      return bodyWidgets[widget.templateId];
    },
    action(payload) {
      this.$emit("action", payload);
    },
    actionAll(payload) {
      this.$emit("actionAll", payload);
    },
    ctaAction(payload) {
      this.$emit("ctaAction", payload);
    },
    showWidget(widget) {
      if (this.$router.currentRoute.value.query.demoWidget) {
        return true;
      }
      return !widget.hidden;
    },
    observeWidgets() {
      const options = {
        rootMargin: "0px",
        threshold: 0.1
      };
      const observer = new IntersectionObserver(this.handleIntersection, options);
      const targets = document.querySelectorAll(".body-widget");
      targets.forEach(target => {
        if (this.cmsMode) {
          target.classList.add("visible");
        } else {
          observer.observe(target);
        }
      });
    },
    handleIntersection(entries) {
      entries.map(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-wrapper {
  .body-widget {
    width: 100%;
    padding-bottom: 16px;
    opacity: 0;
    &.BodyWidgetTextSeparator {
      padding-bottom: 0;
    }
    &.visible {
      opacity: 1;
    }
  }
}
</style>
