<template>
  <div class="body-widget-banner-2-wrapper" :class="[showSquareBorders ? 'show-square-borders' : '']" @click="action">
    <img
      class="body-widget-banner-2__image"
      :src="getBackgroundImage(image, '_appthumb', '_appthumb')"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div class="body-widget-banner-2__overlay" :class="[showSquareBorders ? 'show-square-borders' : '']" />
    <div class="body-widget-banner-2__content">
      <div class="body-widget-banner-2__content__title">
        {{ title }}
      </div>
      <div
        class="body-widget-banner-button"
        :class="[showSquareBorders ? 'show-square-borders' : '']"
        :style="{ 'background-color': ctaColor }"
      >
        {{ ctaText }}
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  computed: {
    title() {
      return translate(this.widget.widgetTitleOptions?.title);
    },
    ctaText() {
      return translate(this.widget.ctaText);
    },
    ctaColor() {
      if (!this.widget.mainColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.mainColor;
    },
    image() {
      return this.widget.photographs ? this.widget.photographs[0] : null;
    },
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-banner-2-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 64px);
  margin-left: 16px;
  flex: 91px 0 0;
  height: 168px;
  position: relative;
  border-radius: 8px;
  background-color: white;
  padding: 16px;
}
.body-widget-banner-2__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
}
.body-widget-banner-2__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: rgba(19, 19, 25, 0.4);
}
.body-widget-banner-2__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  z-index: 1;
}
.body-widget-banner-2__content__title {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: white;
}
.body-widget-banner-button {
  .subheader-black;
  .medium;
  padding: 8px 24px;
  text-align: center;
  width: fit-content;
  color: white;
  border-radius: 30px;
  margin-top: 12px;
}
</style>
