<template>
  <div class="profile-legal-links">
    <stay-top-bars :title="getString('LEGAL_TEXTS')" :left-action="leftActionTopBar" :right-actions="[]" />

    <div class="profile-legal-links__wrapper">
      <div
        v-for="link in filteredLegalLinks"
        :key="link.key"
        class="profile-legal-links__link"
        :class="link.key"
        @click="showLink(link)"
      >
        <div class="profile-legal-links__link__title">{{ linkTitle(link) }}</div>

        <stay-icon
          v-if="link.key !== 'commercialCommunications'"
          legacy
          class="profile-legal-links__link__icon"
          icon="chevron-right"
          size="24"
        />
        <div v-else class="profile-legal-links__link__commercial-communications">
          <div
            class="profile-legal-links__link__commercial-communications__image"
            :class="{ checked: communicationsCheck }"
            @click="toggleCommunicationsCheck"
          />
          <div class="profile-legal-links__link__commercial-communications__label">
            <div class="profile-legal-links__link__commercial-communications__label__subtext">
              {{ getString("GUEST_PANEL_COMMUNICATIONS_ACCEPT_TEXT") }}
            </div>
            <div
              class="profile-legal-links__link__commercial-communications__label__more-info"
              @click="openLink(link.key)"
            >
              {{ getString("MORE_INFO") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <message-popup
      v-if="showPopupHtml"
      style="height: 100%"
      :hide-top-image="true"
      :text="textPopuphtml"
      :html-content="true"
      :action-text="getString('PWA_LEGALTEXT_POPUP_OK')"
      @click="showPopupHtml = false"
    ></message-popup>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { getString, getStringNoEmpty, translate, openExternalURL, previewLegalClause } from "@/services/utils.js";
import legalLinksMixin from "@/mixins/legalLinksMixin.js";
import StayIcon from "@/components/UiKit/Icons/StayIcon.vue";

const StayTopBars = defineAsyncComponent(() => import("@/components/UiKit/Navigation/TopBars/StayTopBars.vue"));

const MessagePopup = defineAsyncComponent(() => import("@/components/MessagePopup"));
export default {
  components: { StayIcon, StayTopBars, MessagePopup },
  mixins: [legalLinksMixin],
  data() {
    return {
      legalLinks: [
        {
          key: "termsAndConditions",
          urlKey: "TERMS_AND_CONDITIONS_URL",
          string: "LOGIN_TERMS"
        },
        {
          key: "privacyPolicy",
          alternativeKey: "PRIVACY_POLICY_URL",
          string: "LOGIN_PRIVACY_POLICY"
        },
        {
          key: "cookiesPolicy",
          string: "VIEW_COOKIE_SETTINGS"
        },
        {
          key: "commercialCommunications"
        }
      ],
      communicationsCheck: false,
      guestConsents: {},
      textPopuphtml: "",
      showPopupHtml: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    strings() {
      return this.$store.state.strings;
    },
    gdprConfiguration() {
      return this.$store.state.gdprConfiguration;
    },
    filteredLegalLinks() {
      return this.legalLinks.filter(link => {
        if (link.key === "commercialCommunications" || link.key === "cookiesPolicy") {
          return (
            this.gdprConfiguration &&
            this.gdprConfiguration[link.key]?.active &&
            this.gdprConfiguration[link.key]?.clauseId
          );
        } else if (link.key === "privacyPolicy") {
          return (
            (this.gdprConfiguration &&
              this.gdprConfiguration[link.key]?.active &&
              this.gdprConfiguration[link.key]?.clauseId) ||
            this.getStringNoEmpty("PRIVACY_POLICY_URL")
          );
        } else if (link.key === "termsAndConditions") {
          return (
            (this.gdprConfiguration &&
              this.gdprConfiguration[link.key]?.active &&
              this.gdprConfiguration[link.key]?.clauseId) ||
            this.getStringNoEmpty("TERMS_AND_CONDITIONS_URL")
          );
        }
        return false;
      });
    },
    leftActionTopBar() {
      return { icon: "white-topbar/Back", action: this.clickedBack };
    }
  },
  created() {
    this.retrieveGuestConsents();
  },
  methods: {
    clickedBack() {
      this.$router.go(-1);
    },
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    getStringNoEmpty(key) {
      return getStringNoEmpty(this.strings, key, this.user);
    },
    linkTitle({ key, string }) {
      if (
        this.gdprConfiguration &&
        this.gdprConfiguration[key]?.active &&
        this.gdprConfiguration[key]?.clauseId &&
        this.gdprConfiguration[key]?.legalTextName
      ) {
        return translate(this.gdprConfiguration[key].legalTextName);
      }
      return this.getString(string);
    },
    showLink({ key }) {
      if (key === "commercialCommunications") {
        return;
      }
      if (key === "cookiesPolicy") {
        this.showCookieSettings();
        return;
      }
      this.openLink(key);
    },
    openLink(key) {
      if (this.gdprConfiguration && this.gdprConfiguration[key]?.active && this.gdprConfiguration[key]?.clauseId) {
        this.goToLegalLinkUrl(this.gdprConfiguration[key].clauseId);
      } else {
        switch (key) {
          case "commercialCommunications":
            this.goToUrlCommercialCommunications();
            break;
          case "termsAndConditions":
            this.goToUrlTerm(this.getString("TERMS_AND_CONDITIONS_URL"), this.getString("LOGIN_TERMS"));
            break;
          case "privacyPolicy":
            this.goToUrlPrivacy(this.getString("PRIVACY_POLICY_URL"), this.getString("LOGIN_PRIVACY_POLICY"));
            break;
        }
      }
    },
    goToLegalLinkUrl(clauseId) {
      this.loading = false;
      this.$store
        .dispatch("getLegalText", {
          id: clauseId
        })
        .then(response => {
          if (response) {
            const htmlContent = previewLegalClause(response);
            if (htmlContent?.isTextHtml) {
              this.textPopuphtml = htmlContent.htmlContentUrl;
              this.showPopupHtml = true;
            }
          }
        })
        .catch(error => {
          this.showErrorMessage(this.getString("ERROR_PLEASE_TRY_AGAIN"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToUrlTerm: function (url, title) {
      if (this.getTermsOpenOutside()) {
        openExternalURL(url);
      } else {
        this.$router.push({
          state: {
            params: {
              url: url,
              title: title,
              notFixed: true
            }
          },
          name: "ExternalWebview"
        });
      }
    },
    goToUrlPrivacy: function (url, title) {
      if (this.getPrivacyOpenOutside()) {
        openExternalURL(url);
      } else {
        this.$router.push({
          state: {
            params: {
              url: url,
              title: title,
              notFixed: true
            }
          },
          name: "ExternalWebview"
        });
      }
    },
    async toggleCommunicationsCheck() {
      this.communicationsCheck = !this.communicationsCheck;
      if (
        !this.guestConsents?.commercialCommunications?.clauseId &&
        this.gdprConfiguration?.commercialCommunications?.clauseId
      ) {
        this.guestConsents.commercialCommunications = {
          clauseId: this.gdprConfiguration.commercialCommunications.clauseId,
          accepted: this.communicationsCheck
        };
      } else if (this.guestConsents?.commercialCommunications?.clauseId) {
        this.guestConsents.commercialCommunications.accepted = this.communicationsCheck;
      }
      const response = await this.$store.dispatch("updateGuestConsents", this.guestConsents);
      if (response?.gdpr) {
        this.guestConsents = response.gdpr;
      } else {
        console.error("Error updating guest consents");
      }
    },
    async retrieveGuestConsents() {
      const { gdpr } = await this.$store.dispatch("getGuestConsents");

      if (!gdpr) {
        console.error("Error retrieving guest consents");
      }

      this.guestConsents = gdpr;
      this.communicationsCheck = gdpr?.commercialCommunications?.accepted;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";

.profile-legal-links {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;

  * {
    box-sizing: border-box;
  }

  &__wrapper {
    overflow-y: auto;
  }

  &__link {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: @size-base*4;

    padding: 0 @size-base*4;
    border-bottom: 1px solid @color-card-borders;

    &.commercialCommunications {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      padding: 16px;
      height: auto;
    }

    &__title {
      .u-text__headline--4;
    }

    &__icon {
      color: @color-secondary-texts;
    }

    &__commercial-communications {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: @size-base*4;

      &__label {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &__subtext {
          .u-text__caption--grey;
        }

        &__more-info {
          .u-text__caption--link;
        }
      }

      &__image {
        height: @size-base*4;
        width: @size-base*4;
        border-width: 1px;
        border-style: solid;
        border-radius: 2px;
        border-color: @color-icons;

        &.checked {
          background-image: url("../../../assets/checkbox-on-white.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-color: var(--main-color);
          border-color: var(--main-color);
        }
      }
    }
  }
}
</style>
