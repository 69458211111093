import * as provider from "./chat-providers/twilio";

const initChatClient = user => {
  return provider.initChatClient(user);
};

const getActiveConversations = () => {
  return provider.getActiveConversations();
};

const getUsersInConversation = conversationName => {
  return provider.getUsersInConversation(conversationName);
};

const enterConversation = (conversationName, contactAttributes, agentData) => {
  return provider.enterConversation(conversationName, contactAttributes, agentData);
};

const inviteUser = (conversation, idUser) => {
  return provider.inviteUser(conversation, idUser);
};

const getConversationMessages = conversation => {
  return provider.getConversationMessages(conversation);
};

const getConversationPreviousPage = (conversation, messages) => {
  return provider.getConversationPreviousPage(conversation, messages);
};

const sendMessageToConversation = (conversation, text) => {
  return provider.sendMessageToConversation(conversation, text);
};

const setTyping = conversation => {
  return provider.setTyping(conversation);
};

const markMessagesAsRead = conversation => {
  return provider.markMessagesAsRead(conversation);
};

const getUnreadMessages = conversation => {
  return provider.getUnreadMessages(conversation);
};

const leaveConversation = conversation => {
  return provider.leaveConversation(conversation);
};

const leaveGuestConversations = establishment => {
  return provider.leaveGuestConversations(establishment);
};

const getIdentity = () => {
  return provider.getIdentity();
};

const setOnlineStatus = status => {
  return provider.setOnlineStatus(status);
};

const isOnline = () => {
  return provider.isOnline();
};

const getConversationAgent = conversation => {
  return provider.getConversationAgent(conversation);
};

const getConversationAgentName = conversation => {
  return provider.getConversationAgentName(conversation);
};

const listenToAgentOnlineStatus = conversation => {
  return provider.listenToAgentOnlineStatus(conversation);
};

export {
  initChatClient,
  getActiveConversations,
  getUsersInConversation,
  enterConversation,
  inviteUser,
  getConversationMessages,
  getConversationPreviousPage,
  sendMessageToConversation,
  setTyping,
  markMessagesAsRead,
  getUnreadMessages,
  leaveConversation,
  leaveGuestConversations,
  getIdentity,
  setOnlineStatus,
  isOnline,
  getConversationAgent,
  getConversationAgentName,
  listenToAgentOnlineStatus
};
