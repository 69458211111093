<template>
  <div class="body-widget-subtitle-6-wrapper" :class="[textCentered ? 'centered' : '']">
    <div v-if="price" class="body-widget-subtitle-6__text" :class="priceColorClass">
      {{ price }}
    </div>
    <div v-if="oldPrice" class="body-widget-subtitle-6__offer" :class="oldPriceColorClass">
      {{ oldPrice }}
    </div>
  </div>
</template>

<script>
import { setCurrencyToPrice } from "@/services/utils.js";
import moment from "moment-timezone";
import dynamicFrontpageMixin from "@/mixins/dynamicFrontpageMixin.js";
import itemTypes from "@/utils/enums/itemTypes";
export default {
  mixins: [dynamicFrontpageMixin],
  props: {
    card: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    },
    textCentered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    dateNow() {
      return this.$store.state.dateNow;
    },
    momentServer() {
      return moment(this.dateNow.time);
    },
    price() {
      if (!this.card.price) return null;
      return setCurrencyToPrice(this.card.price, this.card.currencyData?.symbol);
    },
    oldPrice() {
      if (this.card.itemType === itemTypes.ITEM_TYPE_SERVICE) {
        const timetable = this.getServiceTimetableServeNow(this.card, this.momentServer);
        if (timetable?.length > 0 && timetable[0]?.priceOld && timetable[0].priceOld !== this.card.price) {
          return setCurrencyToPrice(timetable[0].priceOld, this.card.currencyData?.symbol);
        }
      }
      if (!this.card.oldPrice) return null;
      return setCurrencyToPrice(this.card.oldPrice, this.card.currencyData?.symbol);
    },
    priceColorClass() {
      const noOfferClass = this.oldPrice ? "" : "no-offer";
      if (!this.subtitleColor) return "caption-white medium " + noOfferClass;
      return "caption-" + this.subtitleColor + " " + noOfferClass;
    },
    oldPriceColorClass() {
      if (!this.subtitleColor) return "caption-white";
      return "caption-grey";
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-subtitle-6-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &.centered {
    position: absolute;
    left: 16px;
    bottom: 16px;
  }
}
.body-widget-subtitle-6__text {
  padding: 2px 4px;
  text-align: left;
  background: #f9ef61;
  border-radius: 4px;
  margin-right: 8px;
  &.no-offer {
    background: white;
  }
}
.body-widget-subtitle-6__offer {
  text-decoration: line-through;
}
</style>
