<template>
  <div
    class="body-widget-slider-card-1-wrapper"
    :class="[showSquareBorders ? 'show-square-borders' : '']"
    :style="style"
    @click="action"
  >
    <img
      class="body-widget-slider-card-1__image"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :src="getBackgroundImage(image, '_ld', '_ld')"
    />
    <div
      v-if="showGradient"
      class="body-widget-slider-card-1__image-overlay"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div
      v-if="highlightedText"
      class="body-widget-slider-card-1__highlighted-text"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :style="{ 'background-color': highlightColor }"
    >
      {{ highlightedText }}
    </div>
    <div class="body-widget-slider-card-1__content" :class="[isTextCentered ? 'centered' : '']">
      <div class="body-widget-slider-card-1__content__title" :class="textClass">
        {{ title }}
      </div>
      <body-widget-subtitle-wrapper
        v-if="showSubtitle"
        :card-options="cardOptions"
        :card="card"
        :text-centered="isTextCentered"
        class="body-widget-slider-card-1__content__subtitle"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { getImageUrl, translate } from "@/services/utils.js";
import { cardStyles, textAlignments } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetSubtitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetSubtitleWrapper.vue")
    )
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      default: false
    }
  },
  emits: ["action"],
  data() {
    return {
      cardStyles
    };
  },
  computed: {
    title() {
      return translate(this.card.translatableName);
    },
    image() {
      return this.card.photographs ? this.card.photographs[0] : null;
    },
    highlightedText() {
      if (
        !this.card?.highlightedText ||
        typeof this.card.highlightedText !== "object" ||
        this.card.highlightedText.length === 0 ||
        translate(this.card.highlightedText).length === 0
      ) {
        return null;
      }
      return translate(this.card.highlightedText);
    },
    highlightColor() {
      if (!this.cardOptions.highlightColor) {
        return this.$store.state.mainColor;
      }
      return this.cardOptions.highlightColor;
    },
    showSubtitle() {
      return this.cardOptions.showSubtitle && this.cardOptions.subtitleType;
    },
    style() {
      switch (this.cardOptions.style) {
        case cardStyles.CARD_STYLE_BG_4_3:
          return {
            height: "180px",
            width: "240px"
          };
        case cardStyles.CARD_STYLE_BG_9_16:
          return {
            height: "240px",
            width: "140px"
          };
        case cardStyles.CARD_STYLE_BG_1_1:
          return {
            height: "180px",
            width: "180px"
          };
      }
      return {};
    },
    isTextCentered() {
      return this.cardOptions.textAlignment === textAlignments.TEXT_ALIGNMENT_CENTER;
    },
    textClass() {
      let resolvedClass = "";
      if (this.cardOptions.style === cardStyles.CARD_STYLE_BG_4_3) {
        resolvedClass = "h3-white";
      } else {
        resolvedClass = "h4-white";
      }
      if (this.isTextCentered) {
        resolvedClass += " centered";
      }

      return resolvedClass;
    },
    showGradient() {
      return this.cardOptions?.showGradient;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-slider-card-1-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 240px;
  flex: 240px 0 0;
  height: 180px;
  position: relative;
  background: white;
  border-radius: 8px;
}
.body-widget-slider-card-1__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
}
.body-widget-slider-card-1__image-overlay {
  border-radius: 8px;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(180deg, rgba(19, 19, 25, 0) 0%, #131319 100%);
}
.body-widget-slider-card-1__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
  width: calc(100% - 32px);
  &.centered {
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.body-widget-slider-card-1__content__title {
  &.centered {
    text-align: center;
  }
}
.body-widget-slider-card-1__highlighted-text {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 4px 8px;
  border-radius: 16px;
  .caption-white;
}
</style>
