<template>
  <div
    class="body-widget-slider-card-iha-wrapper"
    :class="[showSquareBorders ? 'show-square-borders' : '']"
    @click="action"
  >
    <img
      class="body-widget-slider-card-iha__image"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :src="getBackgroundImage(image, 'original')"
    />
    <div class="body-widget-slider-card-iha__content">
      <div class="body-widget-slider-card-iha__content__title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  computed: {
    title() {
      return translate(this.card.translatableName);
    },
    image() {
      if (this.card.appIcon) {
        return this.card.appIcon;
      } else if (this.card.photographs) {
        return this.card.photographs[0];
      }
      return null;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-slider-card-iha-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 112px;
  flex: 112px 0 0;
  height: 166px;
  position: relative;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 14px 0 rgba(52, 50, 61, 0.1);
}
.body-widget-slider-card-iha__image {
  width: 80;
  margin: 16px;
  height: 80px;
  flex: 80px 0 0;
  border-radius: 4px;
  object-fit: contain;
}
.body-widget-slider-card-iha__content {
  padding: 0 12px 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 54px;
}
.body-widget-slider-card-iha__content__title {
  .caption-black;
  font-weight: 500;
  text-align: center;
}
</style>
