<template>
  <div class="body-widget-title-1-wrapper">
    <div class="body-widget-title-1__title" :style="{ color: titleColor }">
      {{ title }}
    </div>
    <div class="body-widget-title-1__underline" :style="{ 'background-color': underline }" />
  </div>
</template>

<script>
import { translate } from "@/services/utils.js";
export default {
  props: {
    widgetTitleOptions: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    }
  },
  computed: {
    title() {
      return translate(this.widgetTitleOptions.title);
    },
    underline() {
      return this.widgetTitleOptions.underline;
    },
    titleColor() {
      if (this.widgetTitleOptions?.color) {
        return this.widgetTitleOptions.color;
      }
      return this.frontpageTextColor ? this.frontpageTextColor : "#000000";
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-title-1-wrapper {
  width: 100%;
}
.body-widget-title-1__title {
  width: 100%;
  text-align: center;
  .h3-black;
}
.body-widget-title-1__underline {
  margin: 0 auto;
  width: 40px;
  height: 2px;
}
</style>
