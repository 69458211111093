<template>
  <div class="widget-underline4-wrapper">
    <svg
      width="100%"
      height="100%"
      :viewBox="'0 0 375 24'"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Components / Catalogs / Header / Shapes / 01</title>
      <g
        id="Components-/-Catalogs-/-Header-/-Shapes-/-01"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          id="Divider"
          d="M24,0 L351,0 C364.254834,-2.4348735e-15 375,10.745166 375,24 L375,24 L375,24 L0,24 C-1.623249e-15,10.745166 10.745166,2.4348735e-15 24,0 Z"
          :fill="fillColor"
          fill-rule="nonzero"
        ></path>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: "#FFFFFF"
    }
  }
};
</script>

<style></style>
