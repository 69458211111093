<template>
  <div class="body-widget-line-separator-wrapper">
    <div class="body-widget-line-separator" :style="{ height: separationPixels }">
      <div v-if="showLine" class="body-widget-line-separator__image" :style="{ 'background-color': lineColor }" />
    </div>
  </div>
</template>

<script>
import { translate } from "@/services/utils.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    showLine() {
      return this.widget?.showLine;
    },
    separationPixels() {
      return this.widget.separationPixels + "px";
    },
    lineColor() {
      if (!this.widget?.lineColor) {
        return "#C5D0DE";
      }
      return this.widget.lineColor;
    }
  },
  methods: {
    getUnderlineComponent() {
      if (!this.widget?.widgetTitleOptions?.underlineStyle) {
        return null;
      }
      return "Underline" + this.widget.widgetTitleOptions.underlineStyle;
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-line-separator-wrapper {
  width: 100%;
  min-height: 10px;
}
.body-widget-line-separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
.body-widget-line-separator__image {
  width: 100%;
  height: 1px;
}
</style>
