<template>
  <div class="body-widget-title-wrapper">
    <component
      :is="getTitleComponent()"
      class="body-widget-title"
      :widget-title-options="widgetTitleOptions"
      :frontpage-text-color="frontpageTextColor"
      :content-type="contentType"
      @action="emitAction"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { widgetTitles } from "@/utils/enums/widgetsFrontpage.js";
import { contentTypes } from "../../../utils/enums/widgetsFrontpage";
export default {
  components: {},
  props: {
    widgetTitleOptions: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    contentType: {
      type: Number,
      required: false
    }
  },
  emits: ["actionAll"],
  setup() {
    return {
      BodyWidgetTitle1: defineAsyncComponent(
        () => import("@/components/WidgetsFrontpage/WidgetTitles/BodyWidgetTitle1.vue")
      ),
      BodyWidgetTitle2: defineAsyncComponent(
        () => import("@/components/WidgetsFrontpage/WidgetTitles/BodyWidgetTitle2.vue")
      )
    };
  },
  data() {
    return {};
  },
  methods: {
    getTitleComponent() {
      if (!this.widgetTitleOptions?.style) return null;
      return this[widgetTitles[this.widgetTitleOptions.style]];
    },
    emitAction(payload) {
      this.$emit("actionAll", payload);
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-title-wrapper {
  width: 100%;
}
</style>
