<template>
  <div
    class="body-widget-slider-banner-1-wrapper"
    :class="[showSquareBorders ? 'show-square-borders' : '']"
    :style="{
      'background-color': widget.style === bannerStyles.BANNER_STYLE_2 ? ctaColor : backgroundColor,
      'border-color': widget.style === bannerStyles.BANNER_STYLE_2 ? 'rgba(0,0,0,0)' : '#D8DCE5'
    }"
    @click="action"
  >
    <img
      class="body-widget-slider-banner-1__image"
      :src="getBackgroundImage(image, '_appthumb', '_appthumb')"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div class="body-widget-slider-banner-1__content">
      <div
        class="body-widget-slider-banner-1__content__title"
        :style="{ color: widget.style === bannerStyles.BANNER_STYLE_2 ? 'white' : '#34323D' }"
      >
        {{ title }}
      </div>
      <div
        class="body-widget-banner-button"
        :style="{ color: widget.style === bannerStyles.BANNER_STYLE_2 ? 'white' : ctaColor }"
      >
        {{ ctaText }}
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
import { bannerStyles } from "@/utils/enums/widgetsFrontpage.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {
      bannerStyles
    };
  },
  computed: {
    title() {
      return translate(this.widget.widgetTitleOptions?.title);
    },
    ctaText() {
      return translate(this.widget.ctaText);
    },
    ctaColor() {
      if (!this.widget.mainColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.mainColor;
    },
    backgroundColor() {
      if (!this.widget?.backgroundColor) {
        return "white";
      }
      return this.widget.backgroundColor;
    },
    image() {
      return this.widget.photographs ? this.widget.photographs[0] : null;
    },
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-slider-banner-1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 16px;
  margin-right: 16px;
  flex: 91px 0 0;
  height: 91px;
  position: relative;
  box-shadow: 0 3px 14px 0 rgba(52, 50, 61, 0.1);
  border-radius: 4px;
  background-color: white;
  padding: 16px;
}
.body-widget-slider-banner-1__image {
  width: 90px;
  height: 90px;
  flex: 90px 0 0;
  border-radius: 4px;
  object-fit: cover;
}
.body-widget-slider-banner-1__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  height: 90px;
}
.body-widget-slider-banner-1__content__title {
  .h3-black;
  .medium;
}
.body-widget-banner-button {
  .subheader-black;
  .medium;
}
</style>
