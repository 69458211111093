<template>
  <div class="header-widget-wrapper">
    <component
      :is="getWidgetComponent(widget)"
      :widget="widget"
      :frontpage-background-color="frontpageBackgroundColor"
      :frontpage-text-color="frontpageTextColor"
      :fixed-header-visible="fixedHeaderVisible"
      :frontpage-data="frontpageData"
      @height="emitHeight"
      @show-inbox="showInbox"
      @show-user-profile="showUserProfile"
      @shortcut-tapped="shortcutTapped"
      @show-front-side-menu="showFrontSideMenu"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { headerWidgets } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    HeaderWidget1: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/HeaderWidget1.vue"))
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageBackgroundColor: {
      type: String,
      required: false
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    fixedHeaderVisible: {
      type: Boolean,
      required: false
    },
    frontpageData: {
      type: Object || null,
      required: false
    }
  },
  emits: ["height", "showInbox", "showUserProfile", "shortcutTapped", "showFrontSideMenu"],
  methods: {
    getWidgetComponent(widget) {
      return headerWidgets[widget.templateId];
    },
    emitHeight(height) {
      this.$emit("height", height);
    },
    showInbox() {
      this.$emit("showInbox");
    },
    showUserProfile() {
      this.$emit("showUserProfile");
    },
    shortcutTapped(payload) {
      this.$emit("shortcutTapped", payload);
    },
    showFrontSideMenu() {
      this.$emit("showFrontSideMenu");
    }
  }
};
</script>

<style lang="less" scoped></style>
