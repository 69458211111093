<template>
  <div class="profile-activity-card-wrapper" :style="{ width: width ? width : '100%' }" @click="action">
    <img v-if="image" class="profile-activity-card__image" :src="image" />
    <div class="profile-activity-card__content">
      <div class="profile-activity-card-module caption-grey text-left">
        {{ moduleName }}
      </div>
      <div class="profile-activity-card-service-name caption-black medium text-left">
        {{ serviceName }}
      </div>
      <div class="profile-activity-card-date-string caption-black text-left">
        {{ dateString }}
      </div>
      <div v-if="paxAndSite" class="profile-activity-card-date-string caption-black text-left">{{ paxAndSite }}</div>
      <div
        v-if="transactionStatusKey"
        class="profile-activity-card-status text-left"
        :style="{ 'background-color': statusData[transactionStatusKey].background }"
      >
        <img class="profile-activity-card-status-icon" :src="statusData[transactionStatusKey].icon" />
        <div class="profile-activity-card-status-text" :style="{ color: statusData[transactionStatusKey].color }">
          {{ status }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate, capitalized, deleteYearFromMoment, getLocale, getString } from "@/services/utils.js";
import { getTransactionStatusStringKey } from "@/services/utilsRequest.js";
import spacesStatusEnum from "@/services/spacesStatus";
import moment from "moment-timezone";
import iconConfirmed from "@/assets/dynamic-frontpage/Feedback/Confirmed.png";
import iconPending from "@/assets/dynamic-frontpage/Feedback/InProgress.png";
import iconCanceled from "@/assets/dynamic-frontpage/Feedback/Cancelled.png";
const COLOR_CONFIRMED = "#69B36B";
const COLOR_PENDING = "#F9A745";
const COLOR_CANCELED = "#FF7D78";
const BACKGROUND_COLOR_CONFIRMED = "#F6FCF6";
const BACKGROUND_COLOR_PENDING = "#FFF7EE";
const BACKGROUND_COLOR_CANCELED = "#FFF2F1";
export default {
  props: {
    activity: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {
      spaceStatus: spacesStatusEnum,
      statusData: {
        CONFIRMED: {
          icon: iconConfirmed,
          color: COLOR_CONFIRMED,
          background: BACKGROUND_COLOR_CONFIRMED
        },
        PENDING: {
          icon: iconPending,
          color: COLOR_PENDING,
          background: BACKGROUND_COLOR_PENDING
        },
        CANCELED: {
          icon: iconCanceled,
          color: COLOR_CANCELED,
          background: BACKGROUND_COLOR_CANCELED
        },
        REJECTED: {
          icon: iconCanceled,
          color: COLOR_CANCELED,
          background: BACKGROUND_COLOR_CANCELED
        },
        NEW: {
          icon: iconPending,
          color: COLOR_PENDING,
          background: BACKGROUND_COLOR_PENDING
        },
        IN_PROGRESS: {
          icon: iconPending,
          color: COLOR_PENDING,
          background: BACKGROUND_COLOR_PENDING
        },
        PROPOSAL_RECEIVED: {
          icon: iconPending,
          color: COLOR_PENDING,
          background: BACKGROUND_COLOR_PENDING
        },
        PROPOSAL_SENT: {
          icon: iconPending,
          color: COLOR_PENDING,
          background: BACKGROUND_COLOR_PENDING
        },
        PAYMENT_REQUIRED: {
          icon: iconPending,
          color: COLOR_PENDING,
          background: BACKGROUND_COLOR_PENDING
        },
        PRECONFIRMED_AND_PAID: {
          icon: iconPending,
          color: COLOR_PENDING,
          background: BACKGROUND_COLOR_PENDING
        },
        CONFIRMED_AND_PAID: {
          icon: iconConfirmed,
          color: COLOR_CONFIRMED,
          background: BACKGROUND_COLOR_CONFIRMED
        },
        READY_TO_SERVE: {
          icon: iconConfirmed,
          color: COLOR_CONFIRMED,
          background: BACKGROUND_COLOR_CONFIRMED
        }
      }
    };
  },
  computed: {
    image() {
      if (this.activity.photographs) {
        return getImageUrl(this.activity.photographs[0], "_appthumb", "_appthumb");
      }
      if (this.activity.product?.photographs) {
        return getImageUrl(this.activity.product.photographs[0], true);
      }
      if (this.activity.product?.appIcon) {
        return getImageUrl(this.activity.product.appIcon, true);
      }
      return null;
    },
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    moduleName() {
      return this.activity.type;
    },
    serviceName() {
      return this.activity.description;
    },
    paxAndSite() {
      let paxAndSite = "";
      if (this.activity.pax) {
        paxAndSite += this.activity.pax + " pax";
      }
      return paxAndSite.length > 0 ? paxAndSite : null;
    },
    pax() {
      return this.activity.pax || null;
    },
    dateString() {
      const dateToShow = moment(this.activity.startDate).locale(getLocale()).format("LL").toLowerCase();
      return capitalized(deleteYearFromMoment(getLocale(), dateToShow)) + ", " + this.activity.hour;
    },
    transactionStatusKey() {
      if (this.activity.status === undefined) {
        return null;
      }
      const status = this.activity?.bookingSpace?.orderId
        ? this.activity?.bookingSpace?.orderStatus
        : this.activity?.status;
      return getTransactionStatusStringKey(
        status,
        this.activity.serviceType,
        this.activity?.bookingSpace && this.activity?.bookingSpace?.orderId
      );
    },
    status() {
      return getString(this.strings, this.transactionStatusKey, this.user);
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.profile-activity-card-wrapper {
  width: 100%;
  min-height: 60px;
  position: relative;
  border-radius: 4px;
  background-color: white;
  padding: 16px;
  border: 1px solid @color-card-borders;
}
.profile-activity-card__image {
  position: absolute;
  width: 55px;
  height: 55px;
  top: 16px;
  right: 16px;
  border-radius: 4px;
  object-fit: cover;
}
.profile-activity-card__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 60px;
  z-index: 1;
}
.profile-activity-card-service-name,
.profile-activity-card-date-string {
  margin-top: 4px;
}
.profile-activity-card-status {
  width: fit-content;
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
}
.profile-activity-card-status-text {
  font-size: 11px;
  font-weight: 400;
  margin-left: 8px;
}
.profile-activity-card-status-icon {
  flex-basis: 16px;
  width: 16px;
  height: 16px;
  border: none;
}
</style>
