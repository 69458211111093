import { defineAsyncComponent } from "vue";
import { getChatToken } from "../chat";
import store from "../../store";

const activeChannels = null;
const subscribedUsers = {};

const initChatClient = tokenData => {
  return new Promise((resolve, reject) => {
    getToken(tokenData)
      .then(responseToken => {})
      .catch(error => {
        console.error("Error getting token", error);
        reject(error);
      });
  });
};

const listenToTokenEvents = tokenData => {};

const updateToken = tokenData => {
  getToken(tokenData)
    .then(responseToken => {})
    .catch(error => {
      console.error("Error getting new token", error.message);
    });
};

const addClientListeners = client => {
  client.removeAllListeners();
  client.on("channelAdded", channel => {
    listenToChannelEvents(channel, notifyMessageAdded);
  });
  client.on("channelInvited", channel => {
    channel
      .join()
      .then(response => {})
      .catch(error => {
        console.error("Error autojoining", error.message);
      });
  });
  client.on("channelJoined", channel => {});
  client.on("channelLeft", channel => {});
  client.on("channelRemoved", channel => {});
  client.on("channelUpdated", channel => {});
  client.on("memberJoined", member => {});
  client.on("memberLeft", member => {});
  client.on("memberUpdated", member => {});
  client.on("userSubscribed", user => {});
  client.on("userUnsubscribed", user => {});
  client.on("userUpdated", user => {
    if (user.user) {
      autoInviteIfNotMember(user.user);
    }
  });
};

function updateOwnAttributes(client, cmsUser) {
  if (client.user) {
    client.user
      .updateAttributes(cmsUser)
      .then(response => {})
      .catch(error => {
        console.error("Error updating attributes", error);
      });
  }
}

function notifyMessageAdded(message) {
  let agentName = getConversationAgentName(message.channel);
  if (!agentName) {
    agentName = "BOT";
  }
  const detail = {
    conversation: message.channel,
    text: JSON.parse(message.body).text,
    from: agentName
  };
  const event = new CustomEvent("messageAdded", { detail: detail });
  dispatchEvent(event);
}

const getActiveConversations = () => {
  return new Promise((resolve, reject) => {});
};

const getUsersInConversation = channelName => {
  return new Promise((resolve, reject) => {});
};

const filterAgentsChannel = channels => {
  if (channels.length === 0) {
    return channels;
  }
  return channels.filter(channel => {
    return channel.uniqueName.indexOf("agents-channel") === -1;
  });
};

const enterConversation = (channelName, contactAttributes, agentData) => {
  return new Promise((resolve, reject) => {});
};

const isMemberOfChannel = (client, channel) => {
  const identity = client.user.identity;
  let isMember = false;
  channel.members.forEach(member => {
    if (identity === member.identity) {
      isMember = true;
    }
  });
  return isMember;
};

const updateChannelAttributes = (channel, attributes) => {
  channel
    .updateAttributes(attributes)
    .then(channel => {})
    .catch(error => {
      console.error("Error updating attributes", error);
    });
};

const inviteUser = (channel, userId) => {
  channel
    .invite(userId)
    .then(() => {})
    .catch(error => {
      console.error("Error inviting", error);
    });
};

const listenToChannelEvents = (channel, callback) => {
  channel.removeAllListeners();
  channel.on("messageAdded", message => {
    if (message.channel && message.index && message.channel.lastMessage) {
      message.channel.lastMessage.index = message.index;
    }

    callback(message);
  });
  channel.on("typingStarted", member => {
    const event = new CustomEvent("typingStarted", { detail: member });
    dispatchEvent(event);
  });
  channel.on("typingEnded", member => {
    const event = new Event("typingEnded");
    dispatchEvent(event);
  });
  channel.on("memberJoined", member => {});
  channel.on("memberLeft", member => {});
};

const getConversationMessages = channel => {
  return new Promise((resolve, reject) => {
    if (!channel) {
      reject({ error: "CHANNEL_NULL" });
      return;
    }

    channel
      .getMessages()
      .then(messages => {
        resolve(messages);
      })
      .catch(error => {
        console.error("Error getting messages twilio", error);
        reject(error);
      });
  });
};

const getConversationPreviousPage = (channel, messages) => {
  return new Promise((resolve, reject) => {
    if (!channel || !messages) {
      reject({ error: "CHANNEL_NULL" });
      return;
    }

    messages
      .prevPage()
      .then(mss => {
        resolve(mss);
      })
      .catch(error => {
        console.error("Error getting messages", error);
        reject(error);
      });
  });
};

const sendMessageToConversation = (channel, text) => {
  return new Promise((resolve, reject) => {});
};

const setTyping = channel => {};

const markMessagesAsRead = channel => {
  return new Promise((resolve, reject) => {
    if (!channel) {
      reject({ error: "MISSING_CHANNEL" });
      return;
    }
    channel
      .setAllMessagesConsumed()
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const getUnreadMessages = channel => {
  const lastMessage = channel.lastMessage;
  if (!lastMessage) {
    return 0;
  }
  let lastConsumed = channel.lastConsumedMessageIndex;
  if (lastConsumed === null) {
    lastConsumed = -1;
  }
  return lastMessage.index - lastConsumed;
};

const leaveConversation = channel => {
  return new Promise((resolve, reject) => {
    if (!channel) {
      reject({ error: "MISSING_CHANNEL" });
      return;
    }
    channel
      .leave()
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.error("Error leaving conversation twilio", error);
        reject(error);
      });
  });
};

const leaveGuestConversations = establishment => {
  const promises = [];
  if (activeChannels && activeChannels.items) {
    for (let i = 0; i < activeChannels.items.length; i++) {
      const ch = activeChannels.items[i];
      const channelNameComponents = ch.uniqueName.split("-");
      if (ch.uniqueName.indexOf("-system-") === -1 && channelNameComponents[2] === establishment + "") {
        promises.push(leaveConversation(ch));
      }
    }
  }
  Promise.all(promises)
    .then(responses => {})
    .catch(errors => {
      console.error("Errors");
    });
};

const getToken = data => {
  return new Promise((resolve, reject) => {
    getChatToken(
      null,
      response => {
        if (response.data && response.data.token) {
          resolve(response.data.token);
        } else {
          reject();
        }
      },
      error => {
        console.error("Error getting faqs", error);
        reject();
      },
      data
    );
  });
};

const getIdentity = () => {};

const setOnlineStatus = status => {};

const isOnline = () => {};

const getConversationAgentName = channel => {
  const agent = getConversationAgent(channel);
  return agent ? agent.name : null;
};

const getConversationAgent = channel => {
  const members = channel.attributes;
  let member = null;
  Object.keys(members).forEach(memberId => {
    if (memberId.indexOf("cmsUser") !== -1) {
      member = members[memberId];
    }
  });

  return member;
};

const listenToAgentOnlineStatus = channel => {
  let agentMember = false;
  channel
    .getMembers()
    .then(response => {
      if (response.length > 0) {
        response.forEach(member => {
          if (member.identity.indexOf("cmsUser") !== -1) {
            agentMember = member;
          }
        });

        if (agentMember) {
          getMemberUser(agentMember, channel);
        }
      }
    })
    .catch(error => {
      console.error("Error getting members", error.message);
    });
};

const dispatchUpdatedUser = (user, channel) => {
  let status = "disconnected";
  if (
    user.attributes.onlineStatus &&
    user.online &&
    user.attributes.establishment + "" === store.state.establishment.id + ""
  ) {
    status = "connected";
  }
  const event = new CustomEvent("updatedUser", {
    detail: {
      user: user,
      conversation: channel,
      agentStatus: {
        [user.identity]: status
      }
    }
  });
  dispatchEvent(event);
  if (user) {
    autoInviteIfNotMember(user);
  }
};

const getMemberUser = (member, channel) => {
  member
    .getUser()
    .then(user => {
      dispatchUpdatedUser(user, channel);
      listenToUserEvents(user, channel);
    })
    .catch(error => {
      console.error("Error getting member user", error.message);
    });
};

const listenToUserEvents = (user, channel) => {
  user.removeAllListeners();
  user.on("updated", updatedUser => {
    const user = updatedUser.user;
    dispatchUpdatedUser(user, channel);
  });
};

const autoInviteIfNotMember = user => {
  if (user.attributes && user.attributes.invited) {
    const invitedUsers = user.attributes.invited;
    Object.keys(invitedUsers).forEach(cliId => {
      const event = new CustomEvent("guestInvited", {
        detail: { cliId: cliId, conversationName: invitedUsers[cliId], agent: user }
      });
      dispatchEvent(event);
    });
  }
};

export {
  initChatClient,
  getActiveConversations,
  getUsersInConversation,
  enterConversation,
  inviteUser,
  getConversationMessages,
  getConversationPreviousPage,
  sendMessageToConversation,
  setTyping,
  markMessagesAsRead,
  getUnreadMessages,
  leaveConversation,
  leaveGuestConversations,
  getIdentity,
  setOnlineStatus,
  isOnline,
  getConversationAgent,
  getConversationAgentName,
  listenToAgentOnlineStatus
};
