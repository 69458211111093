<template>
  <div class="body-widget-subtitle-1-wrapper">
    <img v-if="subtitle" class="body-widget-subtitle-1__icon" :src="icon" />
    <div v-if="subtitle" class="body-widget-subtitle-1__text" :class="subtitleColorClass">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import { translate } from "@/services/utils.js";
import pinWhite from "@/assets/dynamic-frontpage/cards-icons/location-white.png";
import pinGrey from "@/assets/dynamic-frontpage/cards-icons/location-grey.png";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      icon: pinWhite
    };
  },
  computed: {
    subtitle() {
      if (!this.card.locationDescription) return null;
      return translate(this.card.locationDescription);
    },
    subtitleColorClass() {
      if (!this.subtitleColor) return "caption-white medium";
      return "caption-" + this.subtitleColor;
    }
  },
  created() {
    if (this.subtitleColor === "grey") {
      this.icon = pinGrey;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-subtitle-1-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 20px;
  overflow: hidden;
}
.body-widget-subtitle-1__icon {
  padding-top: 2px;
  margin-right: 4px;
  height: 16px;
}
.body-widget-subtitle-1__text {
  width: 100%;
  text-align: left;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
