<template>
  <div class="body-widget-cta-wrapper">
    <component
      :is="getCtaComponent()"
      class="body-widget-cta"
      :card-options="cardOptions"
      @cta-action="ctaAction"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { ctas } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetCta1: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/CTAs/BodyWidgetCta1.vue"))
  },
  props: {
    cardOptions: {
      type: Object,
      required: true
    }
  },
  emits: ["ctaAction"],
  data() {
    return {};
  },
  methods: {
    getCtaComponent() {
      return ctas[this.cardOptions.ctaType];
    },
    ctaAction() {
      this.$emit("ctaAction");
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-slider-card-wrapper {
  width: 100%;
}
</style>
