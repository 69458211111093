<template>
  <div class="body-widget-title-2-wrapper">
    <div class="body-widget-title2__left">
      <img v-if="icon" class="body-widget-title2__icon" :src="getImageUrl(icon, true)" />
      <div class="body-widget-title-2__title h3-black" :style="{ color: titleColor }">
        {{ title }}
      </div>
    </div>
    <div v-if="action && !showContentType" class="body-widget-title2__right">
      <div class="body-widget-title-2__action" :style="{ color: titleColor }" @click="emitAction">
        {{ action }}
      </div>
      <img class="body-widget-title2__arrow" src="../../../assets/dynamic-frontpage/Next.png" />
    </div>
  </div>
</template>

<script>
import { translate, getImageUrl } from "@/services/utils.js";
import { contentTypes } from "@/utils/enums/widgetsFrontpage.js";
export default {
  props: {
    widgetTitleOptions: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    contentType: {
      type: Number,
      required: false
    }
  },
  emits: ["action"],
  computed: {
    icon() {
      if (this.widgetTitleOptions.style !== 4) return null;
      return this.widgetTitleOptions.icon;
    },
    title() {
      return translate(this.widgetTitleOptions.title);
    },
    titleColor() {
      if (this.widgetTitleOptions?.color) {
        return this.widgetTitleOptions.color;
      }
      return this.frontpageTextColor ? this.frontpageTextColor : "#34323D";
    },
    action() {
      if (!this.widgetTitleOptions.action?.active || !this.widgetTitleOptions.action?.title) return null;
      return translate(this.widgetTitleOptions.action?.title);
    },
    showContentType() {
      return this.contentType === contentTypes.MANUAL_CONTENT;
    }
  },
  methods: {
    getImageUrl,
    emitAction() {
      this.$emit("action", this.widgetTitleOptions.action);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-title-2-wrapper {
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.body-widget-title2__left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-basis: fit-content;
}
.body-widget-title2__right {
  .body-widget-title2__left;
  justify-content: flex-end;
}
.body-widget-title2__icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.body-widget-title-2__title {
  width: 100%;
  text-align: left;
}
.body-widget-title-2__action {
  .caption-gray;
}
.body-widget-title2__arrow {
  width: 18px;
  height: 18px;
  margin-top: 2px;
}
</style>
