<template>
  <div class="body-widget-subtitle-5-wrapper">
    <div v-if="subtitleOpeningHours" class="body-widget-subtitle-5__row">
      <img class="body-widget-subtitle-5__icon" :src="iconTime" />
      <div class="body-widget-subtitle-5__text" :class="subtitleColorClass">
        {{ subtitleOpeningHours }}
      </div>
    </div>
    <div v-if="subtitleTablesLeft" class="body-widget-subtitle-5__row flex-end">
      <img class="body-widget-subtitle-5__icon" :src="iconPlaces" />
      <div class="body-widget-subtitle-5__text" :class="subtitleColorClass">
        {{ subtitleTablesLeft }}
      </div>
    </div>
  </div>
</template>

<script>
import timeWhite from "@/assets/dynamic-frontpage/cards-icons/time-white.png";
import timeBlack from "@/assets/dynamic-frontpage/cards-icons/time-grey.png";
import placesWhite from "@/assets/ic-miniclub-members.png";
import placesBlack from "@/assets/ic-reservas.png";
import { getString } from "@/services/utils.js";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      iconPlaces: placesWhite,
      iconTime: timeWhite
    };
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    locale() {
      if (window.navigator.language) {
        return window.navigator.language;
      }
      if (this.user.locale) {
        return this.user.locale;
      }
      return "en";
    },
    subtitleOpeningHours() {
      if (this.card.openingHours?.start && this.card.openingHours?.openNow) {
        return getString(this.strings, "CLOSES_AT", this.user) + " " + this.card.openingHours.end;
      } else if (this.card.openingHours?.start && this.card.openingHours?.end && !this.card.openingHours?.openNow) {
        return (
          getString(this.strings, "OPENS_AT", this.user) +
          " " +
          this.card.openingHours.start +
          " - " +
          this.card.openingHours.end
        );
      }

      return null;
    },
    subtitleTablesLeft() {
      if (!this.card?.leftTables) return null;
      return this.card?.leftTables + " " + getString(this.strings, "PLACES_LEFT", this.user);
    },
    subtitleColorClass() {
      if (!this.subtitleColor) return "caption-white";
      return "caption-" + this.subtitleColor;
    }
  },
  created() {
    if (this.subtitleColor === "grey") {
      this.iconPlaces = placesBlack;
      this.iconTime = timeBlack;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-subtitle-5-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.body-widget-subtitle-5__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &.flex-end {
    justify-content: flex-end;
  }
}
.body-widget-subtitle-5__icon {
  padding-top: 4px;
  margin-right: 4px;
  height: 10px;
}
.body-widget-subtitle-5__text {
  padding-top: 4px;
  width: 100%;
  text-align: left;
}
</style>
