<template>
  <div class="body-widget-slider-card-upcoming-activity-wrapper" @click="action">
    <img
      class="body-widget-slider-card-upcoming-activity__image"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :src="getBackgroundImage(image, '_appthumb', '_appthumb')"
    />
    <div
      class="body-widget-slider-card-upcoming-activity__image-overlay"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div class="body-widget-slider-card-upcoming-activity__content__title">
      {{ title }}
    </div>
    <div
      class="body-widget-slider-card-upcoming-activity__content"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    >
      <body-widget-activity-card
        :card="computedCard"
        :hide-bottom-border="true"
        :show-square-borders="showSquareBorders"
      />
    </div>
  </div>
</template>

<script>
import itemTypes from "@/utils/enums/itemTypes";
import { defineAsyncComponent } from "vue";
import { getImageUrl, translate, getString } from "@/services/utils.js";
import { cardStyles } from "@/utils/enums/widgetsFrontpage.js";
const ROOMSERVICEV2 = 36;
export default {
  components: {
    BodyWidgetActivityCard: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetActivityCard.vue")
    )
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      default: false
    }
  },
  emits: ["action", "ctaAction"],
  data() {
    return {
      cardStyles
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    strings() {
      return this.$store.state.strings;
    },
    title() {
      return translate(this.card.translatableName);
    },
    image() {
      return this.card.photographs ? this.card.photographs[0] : null;
    },
    computedCard() {
      let centralText = { en: this.card.pax + " pax" };
      if (this.card.itemType === itemTypes.ITEM_TYPE_LEAD && this.card.productName) {
        centralText = this.card.productName;
      } else if (this.card.itemType === itemTypes.ITEM_TYPE_BOOKING && this.card.bookingSpace?.site) {
        centralText = { en: translate(this.card.bookingSpace.site) + ", " + this.card.pax + " pax" };
      } else if (
        this.card.itemType === itemTypes.ITEM_TYPE_BOOKING &&
        this.card.serviceType === ROOMSERVICEV2 &&
        this.user?.room
      ) {
        centralText = { en: getString(this.strings, "ROOM", this.user) + " " + this.user.room };
      }
      return {
        ...this.card,
        eventDay: this.card.startDate,
        translatableName: centralText,
        calendar: { categoryName: { en: this.card.type } }
      };
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    },
    ctaAction() {
      this.$emit("ctaAction", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-slider-card-upcoming-activity-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100vw - 32px);
  flex: calc(100vw - 32px) 0 0;
  position: relative;
  border-radius: 4px;
  background-color: white;
  &.with-cta {
    height: auto;
  }
  @media screen and (min-width: 1000px) {
    width: 468px;
    flex: 468px 0 0;
  }
}
.body-widget-slider-card-upcoming-activity__image {
  width: 100%;
  height: 150px;
  flex: 150px 0 0;
  border-radius: 8px;
  object-fit: cover;
}
.body-widget-slider-card-upcoming-activity__image-overlay {
  border-radius: 8px;
  opacity: 0.8;
  width: 100%;
  height: 150px;
  position: absolute;
  background-image: linear-gradient(180deg, rgba(19, 19, 25, 0) 0%, #131319 80%);
}
.body-widget-slider-card-upcoming-activity__content {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 80px;
  background-color: white;
  height: 80px;
  width: calc(100% - 64px);
  margin: 0 16px 0 16px;
  border-radius: 8px;
  box-shadow: 0 3px 14px 0 rgba(52, 50, 61, 0.1);
  z-index: 1;
}
.body-widget-slider-card-upcoming-activity__content__title {
  .h3-white;
  .medium;
  text-align: center;
  margin-top: -96px;
  padding-bottom: 16px;
  z-index: 1;
}
.body-widget-slider-card-upcoming-activity__highlighted-text {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 4px 8px;
  border-radius: 16px;
  .caption-white;
}
.body-widget-slider-card-upcoming-activity__content__cta {
  margin-top: 8px;
}
</style>
