<template>
  <div class="body-widget-subtitle-wrapper">
    <component
      :is="getSubtitleComponent()"
      class="body-widget-subtitle"
      :card-options="cardOptions"
      :card="card"
      :subtitle-color="subtitleColor"
      :text-centered="textCentered"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { cardSubtitles } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetCardSubtitleLocation: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/CardSubtitles/BodyWidgetCardSubtitleLocation.vue")
    ),
    BodyWidgetCardSubtitleOpeningHours: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/CardSubtitles/BodyWidgetCardSubtitleOpeningHours.vue")
    ),
    BodyWidgetCardSubtitlePriceFrom: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/CardSubtitles/BodyWidgetCardSubtitlePriceFrom.vue")
    ),
    BodyWidgetCardSubtitleBookingTime: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/CardSubtitles/BodyWidgetCardSubtitleBookingTime.vue")
    ),
    BodyWidgetCardSubtitleHoursAndAvailability: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/CardSubtitles/BodyWidgetCardSubtitleHoursAndAvailability.vue")
    ),
    BodyWidgetCardSubtitleOfferPrice: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/CardSubtitles/BodyWidgetCardSubtitleOfferPrice.vue")
    )
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    },
    textCentered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    getSubtitleComponent() {
      if (!this.cardOptions?.subtitleType) return null;
      return cardSubtitles[this.cardOptions.subtitleType];
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-title-wrapper {
  width: 100%;
}
</style>
