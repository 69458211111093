<template>
  <div
    class="body-widget-slider-card-3-wrapper"
    :class="[showCta ? 'with-cta' : '', showSquareBorders ? 'show-square-borders' : '']"
    :style="cardStyle"
    @click="action"
  >
    <img
      class="body-widget-slider-card-3__image"
      :class="[showSquareBorders && !cardIsStyleRound ? 'show-square-borders' : '']"
      :style="cardImageStyle"
      :src="getBackgroundImage(image, '_appthumb', '_appthumb')"
    />
    <div
      v-if="highlightedText"
      class="body-widget-slider-card-3__highlighted-text"
      :class="[showSquareBorders && !cardIsStyleRound ? 'show-square-borders' : '']"
      :style="{ 'background-color': highlightColor }"
    >
      {{ highlightedText }}
    </div>
    <div class="body-widget-slider-card-3__content" :class="[showCta ? 'with-cta' : '']" :style="cardContentStyle">
      <div v-if="category" class="body-widget-slider-card-3__content__category">
        {{ category }}
      </div>
      <div
        class="body-widget-slider-card-3__content__title"
        :style="titleStyle"
        :class="[category ? 'with-category' : '']"
      >
        {{ title }}
      </div>
      <body-widget-subtitle-wrapper
        v-if="showSubtitle"
        :card-options="cardOptions"
        :card="card"
        :subtitle-color="'grey'"
        class="body-widget-slider-card-3__content__subtitle"
      />
      <body-widget-cta-wrapper
        v-if="showCta"
        :card-options="cardOptions"
        class="body-widget-slider-card-3__content__cta"
        @cta-action="ctaAction"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { getImageUrl, translate } from "@/services/utils.js";
import { cardStyles, subtitleColors } from "@/utils/enums/widgetsFrontpage.js";
const BASE_TILE_WIDTH = 108;
const BASE_TILE_HEIGHT = 218;
export default {
  components: {
    BodyWidgetSubtitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetSubtitleWrapper.vue")
    ),
    BodyWidgetCtaWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetCtaWrapper.vue")
    )
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      default: false
    }
  },
  emits: ["action", "ctaAction"],
  data() {
    return {
      cardStyles
    };
  },
  computed: {
    title() {
      return translate(this.card.translatableName);
    },
    category() {
      return this.card?.categoryName ? translate(this.card?.categoryName) : null;
    },
    image() {
      return this.card.photographs ? this.card.photographs[0] : null;
    },
    highlightedText() {
      if (
        !this.card?.highlightedText ||
        typeof this.card.highlightedText !== "object" ||
        this.card.highlightedText.length === 0 ||
        translate(this.card.highlightedText).length === 0
      ) {
        return null;
      }
      return translate(this.card.highlightedText);
    },
    highlightColor() {
      if (!this.cardOptions.highlightColor) {
        return this.$store.state.mainColor;
      }
      return this.cardOptions.highlightColor;
    },
    showSubtitle() {
      return (
        this.cardOptions.showSubtitle &&
        this.cardOptions.subtitleType &&
        this.cardOptions.style !== cardStyles.CARD_STYLE_1_1_ROUND
      );
    },
    subtitleColor() {
      if (!this.cardOptions?.subtitleColor) return null;
      return subtitleColors[this.cardOptions.subtitleColor];
    },
    showCta() {
      return this.cardOptions.showCta;
    },
    cardWidthTiles() {
      const screenWidth = window.innerWidth;
      const sideMargin = 16;
      const intraCardMargin = 8;
      return (screenWidth - 2 * sideMargin - 2 * intraCardMargin) / 3;
    },
    cardHeightTiles() {
      return (BASE_TILE_HEIGHT * this.cardWidthTiles) / BASE_TILE_WIDTH;
    },
    cardStyle() {
      switch (this.cardOptions.style) {
        case cardStyles.CARD_STYLE_TILES_1_1_ROUND: {
          return {
            height: this.cardHeightTiles + "px",
            width: this.cardWidthTiles + "px",
            "flex-basis": this.cardWidthTiles + "px"
          };
        }
        case cardStyles.CARD_STYLE_TILES_1_1_SQUARE:
          return {
            height: this.cardHeightTiles + "px",
            width: this.cardWidthTiles + "px",
            "flex-basis": this.cardWidthTiles + "px"
          };
        case cardStyles.CARD_STYLE_1_1_ROUND:
          return { height: "200px", width: "150px", "flex-basis": "150px", border: "none" };
      }
      return { height: "266px", width: "150px", "flex-basis": "150px" };
    },
    cardImageStyle() {
      switch (this.cardOptions.style) {
        case cardStyles.CARD_STYLE_1_1_ROUND:
          return { "border-radius": "71px" };
        case cardStyles.CARD_STYLE_1_1_SQUARE:
          return { "border-radius": "4px" };
        case cardStyles.CARD_STYLE_TILES_1_1_ROUND:
          return {
            "border-radius": this.cardWidthTiles / 2 + "px",
            width: this.cardWidthTiles - 10 + "px",
            height: this.cardWidthTiles - 10 + "px",
            "flex-basis": this.cardWidthTiles - 10 + "px"
          };
        case cardStyles.CARD_STYLE_TILES_1_1_SQUARE:
          return {
            "border-radius": "4px",
            width: this.cardWidthTiles - 10 + "px",
            height: this.cardWidthTiles - 10 + "px",
            "flex-basis": this.cardWidthTiles - 10 + "px"
          };
      }
      return { "border-radius": "4px" };
    },
    cardContentStyle() {
      switch (this.cardOptions.style) {
        case cardStyles.CARD_STYLE_TILES_1_1_ROUND:
        case cardStyles.CARD_STYLE_TILES_1_1_SQUARE:
          return {
            "flex-basis": this.cardHeightTiles - this.cardWidthTiles - 12 + "px"
          };
        case cardStyles.CARD_STYLE_1_1_ROUND:
          return {
            "align-items": "center"
          };
      }
      return {};
    },
    titleStyle() {
      switch (this.cardOptions.style) {
        case cardStyles.CARD_STYLE_1_1_ROUND:
          return {
            "text-align": "center"
          };
      }
      return {};
    },
    cardIsStyleRound() {
      return this.cardOptions.style === cardStyles.CARD_STYLE_1_1_ROUND;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    },
    ctaAction() {
      this.$emit("ctaAction", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-slider-card-3-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 150px;
  flex: 150px 0 0;
  height: 266px;
  position: relative;
  border: 1px solid @color-gray-2;
  border-radius: 4px;
  background-color: white;
  &.with-cta {
    height: 218px;
  }
}
.body-widget-slider-card-3__image {
  width: calc(100% - 10px);
  margin: 4px 4px 0 4px;
  height: 142px;
  flex: 142px 0 0;
  border-radius: 4px;
  object-fit: cover;
}
.body-widget-slider-card-3__content {
  padding: 8px 12px 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 98px 0 0;
  &.with-cta {
    flex-basis: 102px;
  }
}
.body-widget-slider-card-3__content__category {
  .caption-grey;
  margin-bottom: 4px;
}
.body-widget-slider-card-3__content__title {
  .body-black;
  .medium;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
  &.with-category {
    -webkit-line-clamp: 2;
  }
}
.body-widget-slider-card-3__content__subtitle {
  margin-top: auto;
}
.body-widget-slider-card-3__highlighted-text {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 4px 8px;
  border-radius: 16px;
  .caption-white;
}
</style>
