import { mapActions, mapState } from "vuex";
import {
  getImageUrl,
  getItemUrl,
  getLocale,
  getserviceTimetableAndExceptional,
  getServiceTimetableServeNow,
  getServiceTypeId,
  getString,
  isServiceOpenNowServe,
  moduleForServiceType,
  serviceTypes
} from "../services/utils.js";
import { generateElasticFilter, generateMustIncludeClause, generateShouldIncludeClause } from "../services/elastic.js";
import * as types from "../store/mutation-types";
import moment from "moment-timezone";
import cloneDeep from "lodash-es/cloneDeep";

const Mixin = {
  data() {
    return {
      defaultImage: "../assets/imagedefault.png",
    };
  },
  computed: {
    ...mapState(["mainColor", "enabledModules", "userInbox", "user", "establishment", "dynamicFrontpage", "strings"]),
  },
  methods: {
    ...mapActions(["getBookingConfig"]),
    getImageUrl: getImageUrl,
    getLocale,
    getNowMoment() {
      let timezone = this.establishment.timezone;
      if (!timezone) {
        timezone = "Europe/Madrid";
      }

      return moment.tz(timezone); //.startOf("day").add("22", "hours").add("05", "minutes");
    },
    showItem(object) {
      let serviceTypeId = 0;
      if (object.serviceTypeTemplate) {
        serviceTypeId = object.serviceTypeTemplate.id;
      } else if (object.cmsModule) {
        serviceTypeId = getServiceTypeId(serviceTypes[object.cmsModule]);
      }
      this.$router.push({
        path: getItemUrl(3, object.id, serviceTypeId, this.user, object.cmsModule),
      });
    },
    showElasticItem(object) {
      const serviceType = object.cmsLink.split("/")[4];
      const serviceTypeId = getServiceTypeId(serviceType);
      this.$router.push({
        path: getItemUrl(object.itemType, object.itemId, serviceTypeId, this.user, moduleForServiceType[serviceType]),
      });
    },
    showServiceMap(object) {
      this.$router.push("/map-marker-list?markerList=[" + object.id + "]");
    },
    showActivity(object) {
      this.$router.push({
        path: getItemUrl(3, object.service, 37, this.user, 84),
      });
    },
    getTimetableString(service) {
      const timetable = this.getServiceTimetable(service);

      if (!timetable) {
        return " ";
      }

      let string = "";

      timetable.forEach((slot, index) => {
        if (index < 2) {
          const startsString = slot.starts;
          const endsString = slot.ends;
          if (string.length) {
            string += ", ";
          }
          string += startsString.substring(0, 5) + " - " + endsString.substring(0, 5);
        }
      });

      if (string.length === 0) {
        string = " ";
      }

      return string;
    },
    getOpenCloseStringServerNow(service, now) {
      if (service.temporaryClosed) {
        return this.getString("SERVICE_TEMP_CLOSED");
      }
      if (this.isServiceOpenNowServe(service, now) && this.getServiceClosingTimeServeNow(service, now).length > 1) {
        if (this.isServiceOpen24hServeNow(service, now)) {
          return this.getString("OPEN_24H");
        }
        if (this.isServiceOpeningNextServerNow(service, now)) {
          if (this.isServiceTimeTableCollapsed(service)) {
            return this.getString("CLOSES_AT") + " " + this.getServiceLastClosingTimeServeNow(service);
          }
          return (
            this.getString("CLOSES_AT") +
            " " +
            this.getServiceClosingTimeServeNow(service, now) +
            " · " +
            this.getString("OPENS_AT") +
            " " +
            this.isServiceOpeningNextServerNow(service, now).timeOpening
          );
        }
        return this.getString("CLOSES_AT") + " " + this.getServiceClosingTimeServeNow(service, now);
      } else if (this.isServiceOpeningNextServerNow(service, now)) {
        return this.getString("OPENS_AT") + " " + this.isServiceOpeningNextServerNow(service, now).timeOpening;
      } else if (
        !this.isServiceOpenNowServe(service, now) &&
        this.getServiceOpeningTimeServeNow(service, now).length > 1
      ) {
        return this.getString("OPENS_AT") + " " + this.getServiceOpeningTimeServeNow(service, now);
      }
    },
    isServiceTimeTableCollapsed(service) {
      const dayOfWeek = this.getDayOfWeek(new Date().getDay());
      const daySchedule = service?.serviceTimetable[dayOfWeek];
      if (daySchedule && Array.isArray(daySchedule)) {
        for (let i = 0; i < daySchedule.length - 1; i++) {
          const endsTime = moment(daySchedule[i].ends, "HH:mm");
          const startsNextTime = moment(daySchedule[i + 1].starts, "HH:mm");
          if (endsTime.isAfter(startsNextTime)) {
            return true;
          }
        }
      }
      return false;
    },
    getServiceLastClosingTimeServeNow(service) {
      const dayOfWeek = this.getDayOfWeek(new Date().getDay());
      const daySchedule = service?.serviceTimetable[dayOfWeek];
      if (!daySchedule || !Array.isArray(daySchedule) || daySchedule.length === 0) {
        return "";
      }

      let latestEndTime = moment(daySchedule[0].ends, "HH:mm");

      for (let i = 1; i < daySchedule.length; i++) {
        const currentEndTime = moment(daySchedule[i].ends, "HH:mm");

        if (currentEndTime.isAfter(latestEndTime)) {
          latestEndTime = currentEndTime;
        }
      }

      return latestEndTime.format("HH:mm");
    },
    getOpenCloseString(service) {
      if (service.temporaryClosed) {
        return this.getString("SERVICE_TEMP_CLOSED");
      }
      if (this.isServiceOpen(service) && this.getServiceClosingTime(service).length > 1) {
        if (this.isServiceOpen24h(service)) {
          return this.getString("OPEN_24H");
        }
        if (this.isServiceOpeningNext(service)) {
          return (
            this.getString("CLOSES_AT") +
            " " +
            this.getServiceClosingTime(service) +
            " · " +
            this.getString("OPENS_AT") +
            " " +
            this.isServiceOpeningNext(service).timeOpening
          );
        }
        return this.getString("CLOSES_AT") + " " + this.getServiceClosingTime(service);
      } else if (this.isServiceOpeningNext(service)) {
        return this.getString("OPENS_AT") + " " + this.isServiceOpeningNext(service).timeOpening;
      } else if (!this.isServiceOpen(service) && this.getServiceOpeningTime(service).length > 1) {
        return this.getString("OPENS_AT") + " " + this.getServiceOpeningTime(service);
      }
      //  else if (service.serviceType === "event_calendar" && service.itemType === 3) {
      //   let timetableString = this.getTimetableStringForActivity(service);
      //   if (timetableString) {
      //     service.timetableString = timetableString;
      //   }
      // }
    },
    isServiceOpen24h(service) {
      if (!this.isServiceOpen(service)) {
        return false;
      }
      const timetable = this.getServiceTimetable(service);
      if (timetable[0].starts.substr(0, 5) != "00:00" || timetable[0].ends.substr(0, 5) != "23:59") {
        return false;
      }

      return true;
    },
    isServiceOpen24hServeNow(service, nowS) {
      if (!this.isServiceOpenNowServe(service, nowS)) {
        return false;
      }
      const timetable = this.getServiceTimetableServeNow(service, nowS);
      if (timetable[0].starts.substr(0, 5) != "00:00" || timetable[0].ends.substr(0, 5) != "23:59") {
        return false;
      }

      return true;
    },
    getServiceOpeningTime(service) {
      const timetable = this.getServiceTimetable(service);

      if (!timetable || timetable.length === 0) {
        return " ";
      }

      let string = "";
      const firstTurn = timetable[0];
      if (firstTurn && firstTurn.starts) {
        string = firstTurn.starts.substring(0, 5);
      }

      if (string.length === 0) {
        string = " ";
      }

      return string;
    },
    getServiceOpeningTimeServeNow(service, nowS) {
      const timetable = this.getServiceTimetableServeNow(service, nowS);
      if (!timetable || timetable.length === 0) {
        return " ";
      }
      let string = "";
      const firstTurn = timetable[0];
      if (firstTurn && firstTurn.starts) {
        string = firstTurn.starts.substring(0, 5);
      }
      if (string.length === 0) {
        string = " ";
      }
      return string;
    },
    getServiceClosingTime(service) {
      const timetable = this.getServiceTimetable(service);

      if (!timetable || timetable.length === 0) {
        return " ";
      }

      let currentTurn = false;
      const now = this.getNowMoment();
      const timezone = this.establishment.timezone;

      timetable.forEach(function (slot) {
        if (!currentTurn) {
          const startsString = slot.starts;
          const endsString = slot.ends;
          const startsComponents = startsString.split(":");
          const endsComponents = endsString.split(":");

          const startTime = moment
            .tz(timezone)
            .startOf("day")
            .add(startsComponents[0], "hours")
            .add(startsComponents[1], "minutes");
          const endTime = moment
            .tz(timezone)
            .startOf("day")
            .add(endsComponents[0], "hours")
            .add(endsComponents[1], "minutes");
          if (endTime.isBefore(startTime)) {
            endTime.add(1, "days");
          }
          if (startTime.isSameOrBefore(now) && endTime.isAfter(now)) {
            currentTurn = slot;
          }
        }
      });

      let string = "";
      if (currentTurn && currentTurn.ends) {
        string = currentTurn.ends.substring(0, 5);
      }

      if (string.length === 0) {
        string = " ";
      }

      return string;
    },

    getServiceClosingTimeServeNow(service, nowS) {
      const timetable = this.getServiceTimetableServeNow(service, nowS);

      if (!timetable || timetable.length === 0) {
        return " ";
      }

      let currentTurn = false;
      const now = moment(nowS);
      const timezone = this.establishment.timezone;

      timetable.forEach(function (slot) {
        if (!currentTurn) {
          const startsString = slot.starts;
          const endsString = slot.ends;
          const startsComponents = startsString.split(":");
          const endsComponents = endsString.split(":");

          const startTime = moment(nowS).set("hour", startsComponents[0]).set("minutes", startsComponents[1]);
          const endTime = moment(nowS).set("hour", endsComponents[0]).set("minutes", endsComponents[1]);

          if (endTime.isBefore(startTime)) {
            endTime.add(1, "days");
          }
          if (startTime.isSameOrBefore(now) && endTime.isAfter(now)) {
            currentTurn = slot;
          }
        }
      });

      let string = "";
      if (currentTurn && currentTurn.ends) {
        string = currentTurn.ends.substring(0, 5);
      }

      if (string.length === 0) {
        string = " ";
      }

      return string;
    },
    getServiceTimetable(service) {
      if (!service || !service.serviceTimetable) {
        return false;
      }

      const now = new Date();
      // let dayOfWeek = now.toLocaleString("en", { weekday: "long" }).toLowerCase();
      const dayOfWeek = this.getDayOfWeek(now.getDay());

      const timetablemix = getserviceTimetableAndExceptional(
        service.serviceTimetable,
        service.serviceTimetableExceptions,
      );
      return timetablemix[dayOfWeek];
    },
    getServiceTimetableServeNow(service, nowS) {
      return getServiceTimetableServeNow(service, nowS);
    },
    isServiceOpen(service) {
      if (!service || service.temporaryClosed) {
        return false;
      }
      const timetable = this.getServiceTimetable(service);

      if (!timetable) {
        return false;
      }

      let isOpen = false;
      const now = this.getNowMoment();
      const timezone = this.establishment.timezone;

      timetable.forEach(function (slot) {
        if (!isOpen) {
          const startsString = slot.starts;
          const endsString = slot.ends;
          const startsComponents = startsString.split(":");
          const endsComponents = endsString.split(":");

          const startTime = moment
            .tz(timezone)
            .startOf("day")
            .add(startsComponents[0], "hours")
            .add(startsComponents[1], "minutes");
          const endTime = moment
            .tz(timezone)
            .startOf("day")
            .add(endsComponents[0], "hours")
            .add(endsComponents[1], "minutes");
          let serviceClosesTomorrow = false;
          if (endTime.isBefore(startTime)) {
            endTime.add(1, "days");
            serviceClosesTomorrow = true;
          }
          isOpen =
            (startTime.isSameOrBefore(now) && endTime.isAfter(now)) ||
            (serviceClosesTomorrow && startTime.isSameOrBefore(now));
        }
      });
      return isOpen;
    },
    isServiceOpenNowServe(service, nowS) {
      return isServiceOpenNowServe(service, nowS);
    },
    isServiceOpeningNext(service) {
      const timetable = this.getServiceTimetable(service);

      if (!timetable) {
        return false;
      }
      moment.locale(this.getLocale());
      const self = this;
      let isOpeningNext = false;
      const timeRemainingToOpen = false;
      let timeRemainingString = "";
      let timeOpeningString = "";
      const now = this.getNowMoment();
      const timezone = this.establishment.timezone;

      timetable.forEach(function (slot) {
        if (!isOpeningNext) {
          const startsString = slot.starts;
          const endsString = slot.ends;
          const startsComponents = startsString.split(":");
          const endsComponents = endsString.split(":");

          const startTime = moment
            .tz(timezone)
            .startOf("day")
            .add(startsComponents[0], "hours")
            .add(startsComponents[1], "minutes");
          const endTime = moment
            .tz(timezone)
            .startOf("day")
            .add(endsComponents[0], "hours")
            .add(endsComponents[1], "minutes");

          isOpeningNext = startTime.isAfter(now);
          if (isOpeningNext) {
            timeRemainingString = now.to(startTime);
            timeOpeningString = startTime.format("HH:mm");
          }
        }
      });

      const vari = isOpeningNext ? { timeRemainingToOpen: timeRemainingString, timeOpening: timeOpeningString } : false;
      return isOpeningNext ? { timeRemainingToOpen: timeRemainingString, timeOpening: timeOpeningString } : false;
    },
    isServiceOpeningNextServerNow(service, nowS) {
      const timetable = this.getServiceTimetableServeNow(service, nowS);

      if (!timetable) {
        return false;
      }
      moment.locale(this.getLocale());
      const self = this;
      let isOpeningNext = false;
      const timeRemainingToOpen = false;
      let timeRemainingString = "";
      let timeOpeningString = "";
      const now = moment(nowS);
      timetable.forEach(function (slot) {
        if (!isOpeningNext) {
          const startsString = slot.starts;
          const endsString = slot.ends;
          const startsComponents = startsString.split(":");
          const endsComponents = endsString.split(":");
          const startTime = moment(nowS).set("hour", startsComponents[0]).set("minutes", startsComponents[1]);
          const endTime = moment(nowS).set("hour", endsComponents[0]).set("minutes", endsComponents[1]);
          isOpeningNext = startTime.isAfter(now);
          if (isOpeningNext) {
            timeRemainingString = now.to(startTime);
            timeOpeningString = startTime.format("HH:mm");
          }
        }
      });

      const vari = isOpeningNext ? { timeRemainingToOpen: timeRemainingString, timeOpening: timeOpeningString } : false;
      return isOpeningNext ? { timeRemainingToOpen: timeRemainingString, timeOpening: timeOpeningString } : false;
    },
    happensInInterval(activityStartTime, activityEndTime, intervalStart, intervalEnd) {
      let inTimeInterval = false;
      const timetableStarts = parseInt(activityStartTime.replace(/:/g, ""));
      const timetableEnds = parseInt(activityEndTime.replace(/:/g, ""));
      const starts = parseInt(intervalStart.replace(/:/g, ""));
      const ends = parseInt(intervalEnd.replace(/:/g, ""));
      if (
        (timetableStarts <= starts && timetableEnds >= starts) ||
        (timetableStarts <= ends && timetableEnds >= ends) ||
        (timetableStarts >= starts && timetableEnds <= ends)
      ) {
        inTimeInterval = true;
      }
      return inTimeInterval;
    },
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    moduleKey(serviceType) {
      const moduleId = moduleForServiceType[serviceType];
      const filtered = this.enabledModules.filter((module) => {
        return module.id === moduleId;
      });
      if (filtered.length > 0) {
        return filtered[0].moduleKey;
      }
      return null;
    },
    getElasticFilter(mandatoryObjects, excludeObjects, optionalObjects) {
      let mustIncludeClause = false;
      if (mandatoryObjects) {
        mustIncludeClause = generateMustIncludeClause(mandatoryObjects, excludeObjects);
      }
      let shouldIncludeClause = false;
      if (optionalObjects) {
        shouldIncludeClause = generateShouldIncludeClause(optionalObjects);
      }
      return generateElasticFilter(mustIncludeClause, shouldIncludeClause);
    },
    showTransaction(transaction) {
      const id = transaction.id;
      const description = transaction.description;
      const product = transaction.product;
      const photographs = transaction.photographs;

      if (id.indexOf("booking") !== -1) {
        const bookingId = parseInt(id.split(":")[0], 10);
        let booking = null;
        if (this.userInbox.bookings) {
          booking = this.userInbox.bookings[bookingId];
        }

        if (booking) {
          let locale = "en";
          if (this.user.locale) {
            locale = this.user.locale;
          }
          const path = "/transaction-confirmation/";
          booking.serviceName = description;
          booking.imageUrl = getImageUrl(photographs[0]);
          booking.serviceType = serviceTypes[booking.cmsModule];
          const params = {
            establishment: this.establishment.id,
            locale: locale,
            bookingData: booking,
          };

          this.$router.push({ path: path, state: { params: cloneDeep(params) }, name: "TransactionConfirmation" });
        } else {
          this.$router.push(
            "/transaction-confirmation?bookingId=" +
              bookingId +
              "&serviceId=" +
              transaction.serviceId +
              "&imageId=" +
              photographs[0],
          );
        }
      } else if (id.indexOf("lead") !== -1) {
        const leadId = parseInt(id.split(":")[0], 10);
        let lead = null;
        if (this.userInbox.requests) {
          lead = this.userInbox.requests[leadId];
        }

        if (lead) {
          let locale = "en";
          if (this.user.locale) {
            locale = this.user.locale;
          }
          const path = "/transaction-confirmation/";
          lead.serviceName = description;
          lead.imageUrl = getImageUrl(product.photographs[0]);
          lead.itemName = product.translatableName[locale];
          lead.itemNameEnglish = this.translateEnglishOrDefault(product.translatableName);
          lead.serviceType = serviceTypes[lead.cmsModule];
          const params = {
            establishment: this.establishment.id,
            locale: locale,
            requestData: lead,
          };

          this.$router.push({ path: path, state: { params: cloneDeep(params) }, name: "TransactionConfirmation" });
        } else {
          this.$router.push(
            "/transaction-confirmation?requestId=" +
              leadId +
              "&serviceId=" +
              transaction.serviceId +
              "&imageId=" +
              product.photographs[0],
          );
        }
      } else if (id.indexOf("order") !== -1) {
        const orderId = parseInt(id.split(":")[0], 10);
        let order = null;
        if (this.userInbox.orders) {
          order = this.userInbox.orders[orderId];
        }

        if (order) {
          let locale = "en";
          if (this.user.locale) {
            locale = this.user.locale;
          }
          const path = "/transaction-confirmation/";
          order.serviceName = description;

          const params = {
            establishment: this.establishment.id,
            locale: locale,
            orderData: order,
          };

          this.$router.push({ path: path, state: { params: cloneDeep(params) }, name: "TransactionConfirmation" });
        }
      }
    },
    getItemPrice(item) {
      if (item.itemType === 3 && item.priceFrom && item.currencyData) {
        return item.priceFrom + item.currencyData.symbol;
      } else if (item.itemType === 5 && item.productTypes) {
        const firstProductType = Object.values(item.productTypes)[0];
        if (firstProductType && firstProductType.price) {
          return firstProductType.price + item.currencyData.symbol;
        }
      }
      return false;
    },
    getOffers(services, callback) {
      services.forEach((service) => {
        const serviceTimetableForToday = this.getServiceTimetable(service);
        if (
          serviceTimetableForToday &&
          serviceTimetableForToday.length > 0 &&
          serviceTimetableForToday[0].priceOld &&
          service.priceFrom
        ) {
          service.offerPrice = service.priceFrom + service.currencyData.symbol;
          service.oldPrice = serviceTimetableForToday[0].priceOld + service.currencyData.symbol;
          service.offerTitle = this.translate(serviceTimetableForToday[0].offerTitle);
          if (callback) {
            callback();
          }
        }
      });
    },
    getServiceOffer(cmsModule, serviceId, callback) {
      const serviceType = serviceTypes[cmsModule];
      if (!serviceType || !serviceId) {
        callback(null);
        return;
      }

      this.getBookingConfig({ serviceType: serviceType, serviceId: serviceId })
        .then((bookingConfig) => {
          if (
            bookingConfig.length &&
            bookingConfig[0] &&
            bookingConfig[0].intervals &&
            bookingConfig[0].intervals.length
          ) {
            let offer = false;
            bookingConfig[0].intervals.forEach(function (interval) {
              if (!offer && interval.priceOld) {
                offer = interval;
              }
            });
            if (callback) {
              callback(offer);
            }
          }
        })
        .catch((err) => {
          console.error("Error getting booking config");
          callback(null);
        });
    },
    getStoredData(key) {
      if (!this.dynamicFrontpage || !this.dynamicFrontpage[this.establishment.id]) {
        return;
      }
      return this.dynamicFrontpage[this.establishment.id][key];
    },
    storeData(data, key) {
      if (data && key) {
        this.$store.commit(types.DYNAMIC_FRONTPAGE, { key: key, data: data });
      }
    },
  },
};

export default Mixin;
