<template>
  <div class="body-widget-banner-wrapper">
    <component :is="getBannerComponent()" :widget="widget" :show-square-borders="showSquareBorders" @action="action" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { translate } from "@/services/utils.js";
import { banners } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetBanner1: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Cards/BodyWidgetBanner1.vue")),
    BodyWidgetBanner2: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Cards/BodyWidgetBanner2.vue")),
    BodyWidgetBanner3: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Cards/BodyWidgetBanner3.vue"))
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {};
  },
  computed: {
    text() {
      if (!this.widget?.widgetTitleOptions?.title) {
        return "";
      }
      return translate(this.widget.widgetTitleOptions.title);
    }
  },
  methods: {
    getBannerComponent() {
      if (!this.widget?.style) {
        return null;
      }
      return banners[this.widget.style];
    },
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-text-separator-wrapper {
  width: 100%;
  min-height: 125px;
}
.body-widget-text-separator {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.body-widget-text-separator__text {
  font-weight: 400;
  text-align: center;
  padding: 0 16px;
}
.body-widget-text-separator__image {
  width: auto;
  height: auto;
  margin-top: 8px;
}
</style>
