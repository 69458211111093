<template>
  <div class="profile-side-menu-wrapper" @click.stop="$emit('hide')">
    <div class="profile-side-menu-options-container" @click.stop="doNothing">
      <div class="profile-side-menu-header" :style="{ 'background-color': headerBackgroundColor }">
        <div v-if="isLogged(user)">
          <div class="profile-user-name body-white medium">{{ userName }}</div>
          <div v-if="userCheckIn && userCheckOut" class="profile-user-row">
            <div class="profile-stay-date-label subheader-white">{{ getString("CHECK_IN") }}</div>
            <div class="profile-stay-date subheader-white medium">{{ userCheckIn }}</div>
            <div class="vl" />
            <div class="profile-stay-date-label subheader-white">{{ getString("CHECK_OUT") }}</div>
            <div class="profile-stay-date subheader-white medium">{{ userCheckOut }}</div>
          </div>
          <div class="profile-user-row">
            <img class="profile-user-icon-logged" src="../../../assets/dynamic-frontpage/header-icons/White/Room.png" />
            <div v-if="userRoom" class="profile-user-room body-white bold">{{ userRoom }}</div>
          </div>
        </div>
        <div v-else>
          <div class="profile-not-logged-claim">{{ notLoggedClaim }}</div>
          <div class="profile-login-button" :style="{ 'background-color': loginButtonColor }" @click="logIn">
            <img
              class="profile-login-button-icon"
              src="../../../assets/dynamic-frontpage/header-icons/White/User.png"
            />
            <div class="profile-login-button-text">
              {{ loginButtonText }}
            </div>
          </div>
        </div>
      </div>
      <div class="profile-side-menu-body" :class="{ logged: isLogged(user) }">
        <div v-if="loading" class="loading-container">
          <div class="loader" />
        </div>
        <div v-else class="animated fadeIn">
          <div v-if="allFutureActivity?.length > 0" class="profile-activity-row with-margin">
            <div class="profile-activity-title body-black bold">{{ getString("MY_ACTIVITY") }}</div>
            <div class="profile-activity-row" @click="showAllActivity">
              <div class="profile-activity-view-all caption-grey">{{ getString("VIEW_ALL") }}</div>
              <img class="profile-activity-view-all-arrow" src="../../../assets/dynamic-frontpage/Next.png" />
            </div>
          </div>
          <div v-else class="profile-activity-no-activity">
            <img class="profile-activity-no-activity-icon" src="../../../assets/ic-section-front-activities.png" />
            <div class="profile-activity-no-activity-text caption-grey">{{ getString("NO_ACTIVITY_IN_HOTEL") }}</div>
            <div v-if="allActivity?.length > 0" class="profile-activity-no-activity-show-past" @click="showAllActivity">
              {{ getString("SHOW_PAST_ACTIVITIES") }}
            </div>
          </div>
          <div
            v-for="activity in allFutureActivity"
            :key="'activity-' + activity.id"
            class="profile-activity-card"
            @click="showActivity(activity)"
          >
            <profile-activity-card :activity="activity" :width="'236px'" />
          </div>
        </div>
        <div class="profile-options" :class="{ logged: isLogged(user) }">
          <div
            v-for="option in options"
            :key="'option-' + getString(option.key)"
            class="profile-user-row option"
            @click="emitAction(option)"
          >
            <img class="profile-option-icon" :src="option.icon" />
            <div
              class="profile-option-text body-black medium"
              :class="[
                option.key === 'NOTIFICATIONS' && notificationsUnreadCount > 0 ? 'with-unread-notifications' : ''
              ]"
            >
              {{ getString(option.key) }}
            </div>
            <div v-if="option.key === 'LANGUAGE'" class="profile-selected-language">{{ selectedLanguage }}</div>
            <div
              v-else-if="option.key === 'NOTIFICATIONS' && notificationsUnreadCount > 0"
              class="profile-unread-count"
            />
            <img class="profile-option-arrow" src="../../../assets/dynamic-frontpage/Next.png" />
          </div>
        </div>
        <div
          v-if="isLogged(user)"
          class="profile-login-button logout"
          :style="{ 'background-color': loginButtonColor }"
          @click="logOut"
        >
          <img
            class="profile-login-button-icon"
            src="../../../assets/dynamic-frontpage/header-icons/White/Logout.png"
          />
          <div class="profile-login-button-text">
            {{ getString("LOGOUT") }}
          </div>
        </div>
      </div>
    </div>
    <message-popup
      v-if="showConfirmationLogout"
      :title="getString('ATTENTION')"
      :text="getString('SURE_WANT_TO_LOGOUT')"
      :action-text="getString('YES_LOGOUT')"
      :cancelar-text="getString('NO')"
      @click="userLogout"
      @cancel-click="showConfirmationLogout = false"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import {
  filterNotificationCount,
  getModuleName,
  getString,
  isLogged,
  serviceTypes,
  translate
} from "@/services/utils.js";
import notifications from "@/assets/dynamic-frontpage/header-icons/Black/Notifications.png";
import language from "@/assets/dynamic-frontpage/header-icons/Black/Language.png";
import legalTexts from "@/assets/dynamic-frontpage/header-icons/Black/Cookies.png";
import moment from "moment-timezone";
import appNotificationsMixin from "@/mixins/appNotificationsMixin";

const MessagePopup = defineAsyncComponent(() => import("@/components/MessagePopup.vue"));
const ProfileActivityCard = defineAsyncComponent(
  () => import("@/components/WidgetsFrontpage/Cards/ProfileActivityCard.vue")
);
export default {
  components: {
    MessagePopup,
    ProfileActivityCard
  },
  mixins: [appNotificationsMixin],
  props: {
    frontpageData: {
      type: Object || null,
      required: true
    },
    profileVisible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: [
    "hide",
    "showLogin",
    "loggedOut",
    "showInbox",
    "selectLanguage",
    "showLegalTexts",
    "showActivity",
    "showAllActivity"
  ],
  data() {
    return {
      showConfirmationLogout: false,
      userActivity: [],
      loading: false,
      options: [
        {
          key: "NOTIFICATIONS",
          action: "showInbox",
          icon: notifications
        },
        {
          key: "LANGUAGE",
          action: "selectLanguage",
          icon: language
        },
        {
          key: "LEGAL_TEXTS",
          action: "showLegalTexts",
          icon: legalTexts
        }
      ]
    };
  },
  computed: {
    headerBackgroundColor() {
      return this.frontpageData?.profile?.backgroundColor || "#1C1C28";
    },
    loginButtonColor() {
      return this.frontpageData?.profile?.buttonBackgroundColor || this.$store.state.mainColor;
    },
    notLoggedClaim() {
      return this.frontpageData?.profile?.claimTranslations
        ? this.translate(this.frontpageData.profile.claimTranslations)
        : this.getString("NOT_LOGGED_CLAIM");
    },
    loginButtonText() {
      return this.frontpageData?.profile?.buttonTranslations
        ? this.translate(this.frontpageData.profile.buttonTranslations)
        : this.getString("LOG_IN");
    },
    user() {
      return this.$store.state.user;
    },
    establishment() {
      return this.$store.state.establishment;
    },
    userName() {
      return this.user?.name;
    },
    userRoom() {
      return this.user?.room;
    },
    userCheckIn() {
      if (this.user?.checkIn) {
        const d = new Date(this.user.checkIn);
        return d.toLocaleString(window.navigator.language, {
          day: "2-digit",
          month: "2-digit",
          timeZone: "UTC"
        });
      }
      return null;
    },
    userCheckOut() {
      if (this.user?.checkOut) {
        const d = new Date(this.user.checkOut);
        return d.toLocaleString(window.navigator.language, {
          day: "2-digit",
          month: "2-digit",
          timeZone: "UTC"
        });
      }
      return null;
    },
    strings() {
      return this.$store.state.strings;
    },
    selectedLanguage() {
      if (!this.$store.state.interfaceLanguage) {
        return null;
      }
      return this.$store.state.interfaceLanguage?.toUpperCase();
    },
    myScheduleObjects() {
      const objects = [];
      const now = new moment();
      const mySchedule = this.$store.state.mySchedule;
      if (mySchedule && mySchedule[this.establishment.id]) {
        Object.keys(mySchedule[this.establishment.id]).forEach(key => {
          const item = mySchedule[this.establishment.id][key];
          const activityMoment = moment(item.eventDay + " " + item.hour);
          if (now.isBefore(activityMoment)) {
            item.startDate = item.eventDay;
            item.description = this.translate(item.translatableName);
            item.title = this.getString(getModuleName(item.cmsModule));
            item.serviceType = serviceTypes[item.cmsModule];
            item.serviceId = item.service;
            item.id = ("" + item.id).replace(/:event/g, "") + ":event";
            objects.push(item);
          }
        });
      }
      return objects;
    },
    myVisitorBookings() {
      const objects = [];
      const now = new moment();
      const myVisitorBookings = this.$store.state.myVisitorBookings;
      Object.keys(myVisitorBookings).forEach(key => {
        const item = myVisitorBookings[key];
        const activityMoment = moment(item.bookingDate + " " + item.bookingHour);
        if (now.isBefore(activityMoment)) {
          item.startDate = item.bookingDate;
          item.hour = item.bookingHour.substring(0, 5);
          item.description = item.serviceName;
          item.title = this.getString(getModuleName(item.cmsModule));
          item.serviceType = serviceTypes[item.cmsModule];
          item.serviceId = item.service;
          item.bookingId = item.id;
          item.id = ("" + item.id).replace(/:booking/g, "") + ":booking";
          objects.push(item);
        }
      });
      return objects;
    },
    surveyReplies() {
      const objects = [];
      const surveyReplies = this.$store.state.surveys?.surveyReplies;
      if (surveyReplies) {
        Object.keys(surveyReplies).forEach(key => {
          const items = surveyReplies[key].filter(
            el =>
              (el.hasOwnProperty("unreadStaffMessage") && el.unreadStaffMessage === true) ||
              (el.hasOwnProperty("unreadStaffMessage") && el.hasOwnProperty("unreadGuestMessage"))
          );
          items.forEach(item => {
            item.type = "surveys";
            item.startDate = moment(item.repliedOn).format("YYYY-MM-DD");
            item.hour = moment(item.repliedOn).format("HH:mm");
          });
          objects.push(...items);
        });
      }
      return objects;
    },
    allActivity() {
      const allActivity = [];
      if (this.userActivity?.length > 0) {
        allActivity.push(...this.userActivity);
      }
      if (this.myScheduleObjects?.length > 0) {
        allActivity.push(...this.myScheduleObjects);
      }
      if (this.myVisitorBookings?.length > 0) {
        allActivity.push(...this.myVisitorBookings);
      }
      if (this.surveyReplies?.length > 0) {
        allActivity.push(...this.surveyReplies);
      }
      allActivity.sort((b, a) => {
        return (
          parseInt(b?.startDate?.replace(/-/g, "") + b?.hour?.replace(":", "")) -
          parseInt(a?.startDate?.replace(/-/g, "") + a?.hour?.replace(":", ""))
        );
      });
      return allActivity;
    },
    allFutureActivity() {
      const now = new moment();
      const halfAnHourMargin = new moment().subtract(30, "minutes");
      return this.allActivity
        .filter(item => {
          if (item?.endHour) {
            const activityMoment = moment(item.startDate + " " + item.endHour);
            return now.isBefore(activityMoment);
          } else {
            const activityMoment = moment(item.startDate + " " + item.hour);
            return halfAnHourMargin.isBefore(activityMoment);
          }
        })
        .slice(0, 2);
    },
    notifications() {
      return this.$store.state.notifications;
    },
    notificationsUnreadCount() {
      return filterNotificationCount(this.notifications);
    }
  },
  watch: {
    profileVisible: function () {
      if (this.profileVisible) {
        this.getUserActivityAndInbox();
      }
    }
  },
  methods: {
    isLogged,
    translate,
    async getUserActivityAndInbox() {
      this.userActivity = [];
      if (this.user && this.user.cliId) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch("getActivity");
          if (response) {
            if (response?.length > 0) {
              this.userActivity.push(...response);
            }
          }
          const userInbox = await this.$store.dispatch("getUserInbox");
          this.updateNotificationsSync(userInbox);
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
    },
    doNothing() {
      // This method is used to prevent the click event from bubbling up to the parent element}
    },
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    logIn() {
      this.$emit("showLogin");
    },
    logOut() {
      this.showConfirmationLogout = true;
    },
    async userLogout() {
      this.showLoader = true;
      this.showConfirmationLogout = false;
      const response = await this.$store.dispatch("logout");
      if (response) {
        const event = new Event("userLoggedOut");
        dispatchEvent(event);
        this.$emit("loggedOut");
        this.showLoader = false;
      } else {
        this.showError = true;
        this.showLoader = false;
      }
    },
    emitAction(option) {
      this.$emit(option.action);
    },
    showActivity(activity) {
      this.$emit("showActivity", activity);
    },
    showAllActivity() {
      this.$emit("showAllActivity");
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.profile-side-menu-wrapper {
  height: 100%;
  width: 100vw;
}
.profile-side-menu-options-container {
  background-color: white;
  max-width: 300px;
  width: calc(100vw - 75px);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.profile-side-menu-header {
  max-width: 268px;
  width: calc(100vw - 108px);
  padding: calc(16px + var(--embed-top-safearea)) 16px 16px;
}
.profile-side-menu-body {
  padding: 16px;
  height: calc(100% - 380px);
  overflow: scroll;
  &.logged {
    height: calc(100% - 425px);
  }
}
.profile-not-logged-claim {
  .h4-white;
  .medium;
  margin-top: 8px;
}
.profile-login-button {
  height: 16px;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  &.logout {
    position: absolute;
    bottom: 32px;
    left: 16px;
    margin-bottom: 0;
  }
}
.profile-login-button-icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
.profile-login-button-text {
  .caption-white;
}
.profile-activity-no-activity-show-past {
  .caption-white;
  background-color: var(--main-color);
  padding: 8px 12px;
  border-radius: 20px;
  margin-top: 16px;
  cursor: pointer;
}
.profile-user-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  &.option {
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid @color-card-borders;
    height: 22px;
  }
}
.profile-activity-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.with-margin {
    margin-bottom: 16px;
  }
}
.profile-activity-view-all-arrow {
  width: 18px;
  height: 18px;
  margin-top: 2px;
}
.profile-activity-no-activity {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.profile-activity-no-activity-icon {
  width: 32px;
  height: 32px;
  margin-bottom: 16px;
}
.profile-user-icon-logged {
  height: 15px;
  width: 15px;
  margin-right: 8px;
}
.profile-user-name {
  margin-top: 16px;
}
.profile-stay-date-label {
  margin-right: 12px;
}
.vl {
  width: 1px;
  height: 20px;
  background-color: #ddd;
  opacity: 0.3;
  margin: 0 8px;
}
.profile-options {
  position: absolute;
  bottom: 40px;
  width: calc(100% - 32px);
  &.logged {
    bottom: 95px;
  }
}
.profile-option-icon {
  width: 22px;
  height: 22px;
  margin-right: 16px;
}
.profile-option-text {
  width: calc(100% - 58px);
  &.with-unread-notifications {
    width: fit-content;
  }
}
.profile-option-arrow {
  height: 20px;
  width: 20px;
}
.profile-selected-language {
  .caption-gray;
  margin-right: 8px;
  text-align: right;
}
.profile-activity-card {
  width: 236px;
  margin-bottom: 8px;
}
.profile-unread-count {
  background: #ff7d78;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  margin-left: 8px;
  margin-right: auto;
}
.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.loader {
  border: 3px solid #697181;
  border-radius: 50%;
  border-left-color: #ebedf0;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
