<template>
  <div class="body-widget-subtitle-4-wrapper">
    <div class="body-widget-subtitle-4__row">
      <img v-if="subtitleDate" class="body-widget-subtitle-4__icon" :src="iconDate" />
      <div v-if="subtitleDate" class="body-widget-subtitle-4__text" :class="subtitleColorClass">
        {{ subtitleDate }}
      </div>
    </div>
    <div class="body-widget-subtitle-4__row flex-end">
      <img v-if="subtitleTime" class="body-widget-subtitle-4__icon" :src="iconTime" />
      <div v-if="subtitleTime" class="body-widget-subtitle-4__text" :class="subtitleColorClass">
        {{ subtitleTime }}
      </div>
    </div>
  </div>
</template>

<script>
import timeWhite from "@/assets/time-white.png";
import timeBlack from "@/assets/ic-time-1.png";
import dateWhite from "@/assets/ic-calendar-closed.png";
import dateBlack from "@/assets/ic-reservas.png";
import moment from "moment";
import { getString } from "@/services/utils.js";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    subtitleColor: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      iconDate: dateWhite,
      iconTime: timeWhite
    };
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    locale() {
      if (window.navigator.language) {
        return window.navigator.language;
      }
      if (this.user.locale) {
        return this.user.locale;
      }
      return "en";
    },
    subtitleTime() {
      return this.card.hour;
    },
    subtitleDate() {
      moment.locale(this.locale);
      const dateSubstring = this.card.startDate.substring(0, 10);
      const date = moment(dateSubstring);
      const today = moment();
      let formattedDay = date.format("ddd, DD/MM");
      if (dateSubstring === today.format("YYYY-MM-DD")) {
        formattedDay = getString(this.strings, "TODAY", this.user);
      }

      return formattedDay;
    },
    subtitleColorClass() {
      if (!this.subtitleColor) return "caption-white";
      return "caption-" + this.subtitleColor;
    }
  },
  created() {
    if (this.subtitleColor === "grey") {
      this.iconDate = dateBlack;
      this.iconTime = timeBlack;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.body-widget-subtitle-4-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body-widget-subtitle-4__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &.flex-end {
    justify-content: flex-end;
  }
}
.body-widget-subtitle-4__icon {
  padding-top: 4px;
  margin-right: 4px;
  height: 10px;
}
.body-widget-subtitle-4__text {
  padding-top: 4px;
  width: 100%;
  text-align: left;
}
</style>
